import toast from 'react-hot-toast';

/**
 *
 * @param {error} error
 */
export default function handleCnTimeOut(error) {
    if (error?.code === 'ERR_BAD_RESPONSE') {
        if (error.response.data.data.originalMessage.includes('timed out')) {
            toast.error(this.useTranslate('LBL_SOLVER_UNREACHABLE'));
        }
    }
}
