import React, {useState, useEffect, useContext, useCallback, Suspense} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import Header from '@components/Header';
import Footer from '@components/Footer';
import AppContext from '@context/AppContext';
import utils from 'utils';
import Loader from '@components/Loader';

const RequestsComponent = React.lazy(() => import('@components/RequestComponent'));

export default function Requests(props: RootComponentProps) {
    const params = useParams<{id: string}>();
    const {requestData, setRequestData, defs} = useContext(AppContext);
    const [requestDataStatus, setRequestDataStatus] = useState<string | null>(null);

    /**
     * queryRequest
     * @returns {Promise}
    */
    const queryRequest = async () => {
        if (params.id.length < 40) {
            const response = await utils.fetch('request/get/' + params.id);
            return response;
        }
        const response = await utils.fetch('request/getHash/' + params.id);

        return response;
    };

    const requestQuery = useQuery({queryKey: ['request'], queryFn: queryRequest});

    useEffect(() => {
        if (!requestQuery.isLoading && requestQuery.isError) {
            console.error('requestQuery error', requestQuery.error);
            setRequestDataStatus('error');
            return;
        }

        if (!requestQuery.isLoading && requestQuery.data) {
            setRequestDataStatus('success');
            setRequestData(requestQuery.data);
        }

        if (!requestQuery.isLoading && !requestQuery.data) {
            console.error('requestQuery error', requestQuery.error);
            setRequestDataStatus('error');
        }
    }, [requestQuery, requestQuery.data, setRequestData]);

    const invalidateQuery = useCallback(() => {
        props.queryClient.invalidateQueries({queryKey: ['request']});
    }, [props.queryClient]);

    if (requestDataStatus === 'error') {
        return (
            <div className='pageContainer requestPage'>
                <Header menuItems={defs.menu} onLogin={() => {}} />

                <div className='pageContent'>
                    <div className='RequestComponentWrapper'>
                        <div className='RequestHeader'>
                            <h2 className='RequestErrorMessage'>{utils.useTranslate('LBL_REQUEST_NOT_FOUND')}</h2>
                        </div>
                    </div>
                </div>

                <Footer footerItems={[]} fromContext={true} />
            </div>
        );
    }

    return (
        <div className='pageContainer requestPage'>
            <Header menuItems={defs.menu} onLogin={() => {}} />

            <div className='pageContent'>
                <Suspense fallback={<Loader />}>
                    <RequestsComponent requestData={requestData} allowReply={true} reload={invalidateQuery} />
                </Suspense>
            </div>

            <Footer footerItems={[]} fromContext={true} />
        </div>
    );
}
