import {Link} from 'react-router-dom';
import utils from '@/utils/Utils';
import UserLogin from './Parts/Header/UserLogin';
import LanguageSwitch from './Parts/Header/LanguageSwitch';
import AppContext from '@context/AppContext';
import {useContext} from 'react';
import {Dropdown, MenuButton, Menu, MenuItem} from '@mui/joy';
import spsLogoIcon from '@img/SpsLogoIconDark.svg';
import userIcon from '@img/userIcon.png';

interface MobileNavProps {
    menuItems: MenuItem[];
    onLogin: () => void;
}

export default function MobileNav({menuItems, onLogin} :MobileNavProps) {
    const {logOut, user} = useContext(AppContext);

    return (
        <div className="mobileNav">
            <div className="mobileNavLinks">
                {menuItems.filter((item: MenuItem) => item.type !== 'icon').map((item, index) => {
                    if (user?.type !== 'internal' && item.type === 'internal') {
                        return;
                    }
                    return (
                        <Link
                            key={index}
                            to={item.link}
                            className={'bold item' + (item.active ? ' active' : '')}
                        >
                            {utils.useTranslate(item.label)}
                        </Link>
                    );
                })}
            </div>
            <div className='mobileNavSocialMedia'>
                {menuItems.filter((item: MenuItem) => item.type === 'icon').map((item, index) => (
                    <Link key={index} to={item.link} className={'item'}>
                        <div className={`${item.icon}`}/>
                    </Link>
                ))}
            </div>
            <div className='mobileNavFooter'>
                <div className='languageSwitchWrapper'>
                    <LanguageSwitch />
                </div>
                <div className='loginWrapper'>
                    {!user?.name ?
                        <UserLogin onLogin={onLogin}/> : (
                            <Dropdown>
                                <MenuButton className='user'>
                                    <img src={userIcon}
                                        alt='user icon'
                                        className='headerAvatar'
                                    />
                                    <div className='username bold'>
                                        {user?.name ? user?.name : utils.useTranslate('LBL_LOGIN')}
                                        {user?.name && user?.type === 'internal' && (
                                            <img src={spsLogoIcon} alt='SPS Logo' className='spsLogoIconInternal' />
                                        )}
                                    </div>
                                </MenuButton>
                                <Menu size="sm">
                                    {/* <MenuItem>Profile</MenuItem>
                                    <MenuItem>My account</MenuItem> */}
                                    <MenuItem onClick={logOut}>{utils.useTranslate('LBL_LOGOUT')}</MenuItem>
                                </Menu>
                            </Dropdown>
                        )}
                </div>
            </div>
        </div>
    );
}
