import {ErrorInfo, useState} from 'react';
import Button from '@components/formElements/Button';

// error UI component
type Props = {
    error: Error;
    info: ErrorInfo
};

export default function ErrorComponent(props: Props) {
    const [state, setState] = useState({
        showDetail: false,
    });

    /**
     * handleReload
     */
    const handleReload = () => {
        window.location.reload();
    };

    return (
        <div className='errorComponent'>
            <div className='errorComponentContainer'>
                <h1 className='errorComponentTitle'
                    onClick={() => setState({showDetail: !state.showDetail})}>
                    Niečo sa nepodarilo...
                </h1>
                <p className='errorComponentMessage'>
                    Ospravedlňujeme sa, stránka je dočasne nedostupná.<br/>
                    Skúste to prosím neskôr.
                </p>

                {state.showDetail &&
                    <div className='errorComponentDetails'>
                        <p className='errorComponentMessageStack'>{props.error.message}</p>
                        <p className='errorComponentMessageStack'>{props.info.componentStack}</p>
                    </div>
                }
            </div>

            <Button
                className='errorComponentButton'
                onClick={handleReload}
                variant='primary'
            >
                <span className='iconfa-refresh' /> Skúsiť znova
            </Button>

        </div>
    );
}
