import utils from '@/utils/Utils';
import {Link} from 'react-router-dom';
import Sk from './formElements/Sk';
import LanguageSwitch from './Parts/Header/LanguageSwitch';

export default function ContactHeader() {
    const loaded = utils.useTranslate().isLangLoaded();

    return (
        <div className="contactHeaderWrapper">
            <div className="contactHeader">
                {loaded && (<>
                    <div>{utils.useTranslate('LBL_MENU_COMPANY')}</div>
                    <div className="rightSide">
                        <Sk className='rightSideItem' loaded={loaded}>
                            <Link to='/contact' className="rightSideItem">{utils.useTranslate('LBL_CONTACT')}</Link>
                        </Sk>
                        <a href={'tel:' + utils.useTranslate('LBL_MENU_PHONE')} className="rightSideItem">
                            {utils.useTranslate('LBL_MENU_PHONE')}
                        </a>
                        <LanguageSwitch />
                    </div>
                </>)}
            </div>
        </div>
    );
}
