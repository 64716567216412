/**
 *
 * @param {string} packageNr
 * @returns {string} formatted packageNr (14 characters no without -)
 */
export default function formatPackageNr(packageNr) {
    let modifiedString = packageNr.replace(/-/g, '');

    if (modifiedString.length > 14) {
        modifiedString = modifiedString.substring(0, 14);
    }

    return modifiedString;
}
