import {TextareaAutosize as BaseTextareaAutosize} from '@mui/base/TextareaAutosize';

interface TextareaProps {
    className?: string;
    placeholder?: string;
    value?: string;
    maxRows?: number;
    minRows?: number;
    defaultValue?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    id?: string;
    label?: string;
    required?: boolean;
    errorHelperText?: string;
    error?: boolean;
}

export default function Textarea({
    className,
    placeholder,
    value,
    maxRows,
    minRows,
    defaultValue,
    disabled,
    onChange,
    id,
    label,
    required,
    errorHelperText,
    error,
}: TextareaProps) {
    const requiredPlaceholder = !label && required ? ' *' : '';

    return (
        <div className={'textareaWrapper ' + className}>
            {label && <label className='formElementLabel'>{label}{required ? <span className='required'>*</span> : null}</label>}
            <BaseTextareaAutosize
                className={'Textarea'}
                placeholder={placeholder + requiredPlaceholder}
                value={value}
                maxRows={maxRows}
                minRows={minRows}
                defaultValue={defaultValue}
                disabled={disabled}
                onChange={(event) => onChange && onChange(event.target.value)}
                id={id}
            />
            {error && errorHelperText && <div className='errorText'>{errorHelperText}</div>}
        </div>
    );
}
