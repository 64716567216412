export default function LanguageSwitch() {
    /**
     *
     * @param {string} language
     */
    const setLanguage = (language) => {
        localStorage.setItem('mySPS_language', language);
        window.location.reload();
    };

    const language = localStorage.getItem('mySPS_language');

    return (
        <div className="languageSwitch">
            <span
                className={`language ${language === 'sk' || !language ? 'active' : ''}`}
                onClick={() => setLanguage('sk')}
            >SK</span>
            <span className='divider'>|</span>
            <span className={`language ${language === 'en' ? 'active' : ''}`} onClick={() => setLanguage('en')}>EN</span>
        </div>
    );
}
