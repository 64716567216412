/**
 * setStorage - set value to local storage
 * @param {string} key
 * @param {any} paramValue
 */
export default function setStorage(key: string, paramValue: any) {
    let value = null;
    if (typeof paramValue === 'object' && paramValue != null) {
        value = JSON.stringify(paramValue);
    } else {
        value = paramValue;
    }
    if (window.localStorage) {
        localStorage.setItem(key, value);
    } else {
        console.error('Local storage is not supported');
    }
}
