import {Skeleton} from '@mui/material';

interface SkProps {
    children?: React.ReactNode;
    len?: number;
    height?: number;
    loaded?: boolean;
    className?: string;
}
/**
 * Sk - Skeleton rendering component
 * component renders children or skeleton
 * @param {SkProps} props
 * @return {React.ReactElement | string}
 */
export default function Sk({children, len, height, loaded, className}: SkProps) {
    if (!loaded) {
        return (
            <Skeleton variant="rectangular" className={'TxSkeleton ' + className} style={{width: len + 'em', height: height + 'em'}} />
        );
    }

    return children || null;
}
