import {useContext, useEffect, useState} from 'react';
import Modal from 'react-modal';
import AppContext from '@/context/AppContext';
import InfoPopup from './partials/InfoPopup';
import ConfirmPopup from './partials/ConfirmPopup';
import FlexibleDeliveryPopup from './TrackingActions/FlexibleDeliveryPopup';
import ResendCustomerPinPopup from './TrackingActions/ResendCustomerPinPopup';
import RejectShipmentPopup from './TrackingActions/RejectShipmentPopup';
import ReportDamagePopup from './TrackingActions/ReportDamagePopup';
import ChangePPDPopup from './TrackingActions/ChangePPDPopup';
import TicketForCustomerServicePopup from './TrackingActions/TicketForCustomerServicePopup';
import ExtendStoragePeriodPopup from './TrackingActions/ExtendStoragePeriodPopup';
import QuestionForParcelShop from './TrackingActions/QuestionForParcelShopPopup';
import QuestionsToAdditionalServices from './TrackingActions/QuestionsToAdditionalServicesPopup';

// props from Popup type
interface PopupProps {
    id: string;
    title: string;
    type: 'success' | 'error' | 'info' | 'confirm' | 'custom' | 'cnAction';
    message?: string;
    buttons?: PopupButton[];
    component?: any;
    className?: string;
    data?: any;
    cnAction?: string;
    icon?: string;
    onClose?: () => void;
    onConfirm?: () => void;
    afterOpen?: () => void;
}

export default function Popup({
    id,
    title,
    type,
    message,
    buttons,
    component,
    className,
    data,
    cnAction,
    icon,
    onClose,
    onConfirm,
    afterOpen,
}: PopupProps) {
    const {closePopup} = useContext(AppContext);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        // to prevent warning Cannot register already open modal
        setModalIsOpen(true);
    }, []);

    /**
     *
     */
    const afterOpenModal = () => {
        afterOpen && afterOpen();
    };
    /**
     *
     */
    const closeModal = () => {
        onClose && onClose();
        closePopup(id);
    };

    /**
     * renderPopupContent
     * @returns {JSX.Element}
     */
    const renderPopupContent = () => {
        switch (type) {
            case 'info':
                return <InfoPopup
                    type='info'
                    message={message}
                    buttons={buttons ? buttons : [{
                        label: 'LBL_OK_BTN',
                        type: 'primary',
                        action: () => {
                            closeModal();
                        },
                    }]}
                />;
            case 'success':
                return <InfoPopup
                    message={message}
                    type='success'
                    buttons={[
                        {
                            label: 'LBL_OK_BTN',
                            type: 'primary',
                            action: () => {
                                onConfirm && onConfirm();
                                closeModal();
                            },
                        },
                    ]}
                />;
            case 'error':
                return <InfoPopup
                    message={message}
                    type='error'
                    buttons={[
                        {
                            label: 'LBL_OK_BTN',
                            type: 'primary',
                            action: () => {
                                onConfirm && onConfirm();
                                closeModal();
                            },
                        },
                    ]}
                />;
            case 'confirm':
                return <ConfirmPopup
                    message={message}
                    closePopup={closeModal}
                    onConfirm={onConfirm}
                    onClose={onClose}
                />;
            case 'cnAction':
                switch (cnAction) {
                    case 'flexibleDelivery':
                        return <FlexibleDeliveryPopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'resendCustomerPin':
                        return <ResendCustomerPinPopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'rejectShipment':
                        return <RejectShipmentPopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'reportDamage':
                        return <ReportDamagePopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'changePPD':
                        return <ChangePPDPopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'ticketForCustomerService':
                        return <TicketForCustomerServicePopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'extendStoragePeriod':
                        return <ExtendStoragePeriodPopup
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'questionForParcelShop':
                        return <QuestionForParcelShop
                            data={data}
                            closePopup={closeModal}
                        />;
                    case 'questionsToAdditionalServices':
                        return <QuestionsToAdditionalServices
                            data={data}
                            closePopup={closeModal}
                        />;
                }
                break;
            case 'custom':
                return component;
            default:
                return <InfoPopup message={message} buttons={buttons} />;
        }
    };

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Example Modal"
                overlayClassName="popupOverlay"
                className={`popup ${className}`}
                ariaHideApp={false}
            >
                <div className='popupHeader'>
                    {icon && <div className={`popupIcon iconfa-${icon} iconfas-${icon}`} />}
                    <h2 className='popupTitle'>{title}</h2>
                    <div className='popupClose iconfa-close' onClick={closeModal}></div>
                </div>

                {renderPopupContent()}
            </Modal>
        </div>
    );
}
