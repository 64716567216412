import {createContext, useState, useEffect} from 'react';
import utils from 'utils';

interface IInternalContext {
    internalUser: User;
    internalUserToken: string;
    setInternalUser: (user: User) => void;
    setInternalUserToken: (token: string) => void;
}

const defaultState: IInternalContext = {
    internalUser: undefined,
    internalUserToken: '',
    setInternalUser: () => {},
    setInternalUserToken: () => {},
};

export const InternalContext = createContext<IInternalContext | null>(defaultState);

export const InternalContextProvider = (props: any) => {
    // states of Context
    const [internalUser, setInternalUser] = useState<User>(undefined);
    const [internalUserToken, setInternalUserToken] = useState<string>('');
    const [tokenValidated, setTokenValidated] = useState<boolean>(false);

    // functions of Context (only functions that interact with states others should be in utils)
    useEffect(() => {
        const userToken = utils.getStorage('internalUserToken');
        if (userToken && userToken !== '') {
            setInternalUserToken(userToken);
        }
        const user = utils.getStorage('internalUser');
        if (user) {
            setInternalUser(user);
        }

        if (!tokenValidated && userToken && userToken !== '') {
            utils.fetch('internal/validate-token', false, userToken).then((response) => {
                if (response.status) {
                    setTokenValidated(true);
                } else {
                    setInternalUserToken('');
                    setInternalUser(undefined);
                    utils.setStorage('internalUser', {});
                    utils.setStorage('internalUserToken', '');
                }
            }).catch((error) => {
                console.error('InternalContextProvider error:', error);
                setInternalUserToken('');
                setInternalUser(undefined);
                utils.setStorage('internalUser', {});
                utils.setStorage('internalUserToken', '');
            });
        }
    }, []);

    // exported values of Context
    const contextValue = {
        internalUser,
        internalUserToken,
        setInternalUser,
        setInternalUserToken,
    };

    return (
        <InternalContext.Provider value={contextValue}>
            {props.children}
        </InternalContext.Provider>
    );
};

export default InternalContext;
