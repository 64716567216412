/**
 * getStatusColor
 * @param {string} status
 * @returns {string} color
 */
export default function getStatusColor(status) {
    switch (status) {
        case 'created':
        case 'pending':
        case 'not-assigned':
            return 'yellow';
        case 'in-progress':
            return 'blue';
        case 'answered':
            return 'green';
        case 'closed':
            return 'red';
        case 'waiting':
            return 'orange';
        case 'expired':
            return 'gray';
        default:
            return 'gray';
    }
}
