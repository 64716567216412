/**
 * Validate if email is valid
 * @param {string} email
 * @return {boolean} Returns true if the email is valid, otherwise false
 */
export default function validateEmail(email: string): boolean {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
}
