import {useMemo} from 'react';
import Input from '@/components/formElements/Input';
import Select from '@/components/formElements/Select';
import Sk from '@/components/formElements/Sk';
import utils from '@/utils/Utils';
import Textarea from '@/components/formElements/Textarea';
import Date from '@/components/formElements/Date';

interface CnFormInterface {
    fields: object;
    data?: object;
    onChange: (value, name) => void;
    loaded: any;
}

export default function CnForm({fields, data, onChange, loaded}: CnFormInterface) {
    const formFields = useMemo(() => {
        return Object.keys(fields).map((key) => {
            const field = fields[key];

            if (field.hidden) {
                return null;
            }

            const commonProps = {
                value: (data ? data[field.name] : field.value) || '',
                required: field.required,
                onChange: (value) => onChange(value, field.name),
                placeholder: field.label ? utils.useTranslate(field.label) : '',
                label: field.label ? utils.useTranslate(field.label) : '',
                disabled: field.readonly,
                className: field.className,
            };

            if (field.fieldType === 'enum') {
                return (
                    <Sk key={field.name} loaded={loaded} className='inputSkeleton'>
                        <Select
                            {...commonProps}
                            options={field.options}
                        />
                    </Sk>
                );
            } else if (field.fieldType === 'textarea') {
                return (
                    <Sk key={field.name} loaded={loaded} className='inputSkeleton'>
                        <Textarea
                            {...commonProps}
                            minRows={4}
                            error={field.error}
                            errorHelperText={utils.useTranslate(field.errorHelperText)}
                        />
                    </Sk>
                );
            } else if (field.fieldType === 'date') {
                return (
                    <Sk key={field.name} loaded={loaded} className='inputSkeleton'>
                        <Date
                            {...commonProps}
                            disableFuture={field.disableFuture}
                            disablePast={field.disablePast}
                            maxDateDays={field.maxDateDays}
                            // error={field.error}
                            // errorHelperText={utils.useTranslate(field.errorHelperText)}
                        />
                    </Sk>
                );
            } else {
                return (
                    <Sk key={field.name} loaded={loaded} className='inputSkeleton'>
                        <Input
                            {...commonProps}
                            type={field.type}
                            error={field.error}
                            errorHelperText={utils.useTranslate(field.errorHelperText)}
                        />
                    </Sk>
                );
            }
        });
    }, [fields, data, onChange, loaded]);

    return formFields;
}
