/**
 * rest - a generic rest client using axios
 */
import axios from 'axios';

type Rest = {
    get: (url: string) => Promise<any>;
    post: (url: string, data: any) => Promise<any>;
};

const rest: Rest = {} as Rest;

/**
 * rest get - fetches data from the given url
 * @param {string} url
 * @param {any} headers
 * @returns Promise
 */
rest.get = async (url: string, headers?: any) => {
    // on 404 return data
    return axios.get(url, {
        headers,
        validateStatus: (status) => status === 200 || status === 404,
    }).then((res) => res.data);
};

/**
 * rest post - posts data to the given url
 * @param {string} url
 * @param {any} data
 * @param {any} headers
 * @returns Promise
 */
rest.post = async (url: string, data: any, headers?: any) => {
    // on 404 return data
    return axios.post(url, data, {
        headers,
        validateStatus: (status) => status === 200 || status === 404,
    }).then((res) => res.data);
};

export default rest;
