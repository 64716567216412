import {useContext, useState, useCallback} from 'react';
import toast from 'react-hot-toast';
import {InternalContext} from '@context/InternalContext';
import {AppContext} from '@context/AppContext';
import Input from './formElements/Input';
import Button from './formElements/Button';
import utils from 'utils';

export default function InternalLogin() {
    const {setInternalUser, setInternalUserToken} = useContext(InternalContext);
    const {setUser} = useContext(AppContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleInternalLogin = useCallback(() => {
        // Call API to validate the user
        utils.post('internal/login', {username, password}).then((response) => {
            if (!response.data?.token) {
                toast.error(utils.useTranslate('LBL_INTERNAL_LOGIN_ERROR'));
                return;
            }

            const internalUserData = {
                id: null,
                name: username,
                email: response.data?.userData?.email || null,
                avatar: null,
                type: 'internal',
            };

            // Set the user and token in the context
            utils.setStorage('internalUser', internalUserData);
            utils.setStorage('internalUserToken', response.data.token);
            toast.success(utils.useTranslate('LBL_INTERNAL_LOGIN_SUCCESS'));
            setTimeout(() => {
                setInternalUser(internalUserData);
                setInternalUserToken(response.data.token);
                setUser(internalUserData);
            }, 500);
        }).catch((error) => {
            console.error('InternalLogin error:', error);
            // if status 401, show error message wrong username or password
            if (error.response.status === 401) {
                toast.error(utils.useTranslate('LBL_INTERNAL_LOGIN_ERROR_CREDENTIALS'));
                return;
            }
            toast.error(utils.useTranslate('LBL_INTERNAL_LOGIN_ERROR'));
        });
    }, [username, password, setInternalUser, setInternalUserToken, setUser]);

    return (
        <div className='InternalLoginWrapper'>
            <div className='InternalLoginContainer'>
                <h2><span className='iconfas-lock'></span> {utils.useTranslate('LBL_INTERNAL_LOGIN_REQUIRED')}</h2>
                <div className='InternalLoginForm'>
                    <Input
                        type='text'
                        placeholder={utils.useTranslate('LBL_INTERNAL_USERNAME')}
                        value={username}
                        onChange={(value) => setUsername(value)}
                    />
                    <Input
                        type='password'
                        placeholder={utils.useTranslate('LBL_INTERNAL_PASSWORD')}
                        value={password}
                        onChange={(value) => setPassword(value)}
                    />
                    <Button
                        variant='primary'
                        className='InternalLoginButton'
                        onClick={handleInternalLogin}
                    >
                        {utils.useTranslate('LBL_INTERNAL_LOGIN_BTN')}
                    </Button>
                </div>
            </div>
        </div>
    );
}
