/**
 * Validate if phone number is valid
 * @param {string} phone
 * @return {boolean} Returns true if the phone number is valid, otherwise false
 */
export default function validatePhone(phone: string): boolean {
    // Regular expression for validating phone numbers
    const phoneRegex = /^\+421\d{9}$|^090\d{6,9}$/;

    // Test the phone number against the regular expression
    return phoneRegex.test(phone);
}
