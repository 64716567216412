import {useState} from 'react';
import Button from '@/components/formElements/Button';
import utils from '@/utils/Utils';
import CnForm from '@/helpers/tracking/CnForm';
import checkRequiredFields from '@/helpers/tracking/checkRequiredFields';
import toast from 'react-hot-toast';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

export default function QuestionForParcelShop({data, closePopup}: PopupProps) {
    const shipmentInfo = data.shipmentInfo[0];
    const options = utils?.lang?.data?.parcel_shop_question_reason;
    const [fields, setFields] = useState({
        shipmentNumber: {
            name: 'shipmentNumber',
            label: 'LBL_SHIPMENT_NR',
            required: true,
            readonly: true,
            hidden: true,
            value: shipmentInfo.shipmentNr,
        },
        phone: {
            name: 'phone',
            label: 'LBL_DELIVERY_PHONE',
            required: true,
            error: false,
            errorHelperText: '',
            value: shipmentInfo.recipientPhone,
        },
        email: {
            name: 'email',
            label: 'LBL_DELIVERY_MAIL',
            required: true,
            type: 'email',
            error: false,
            errorHelperText: '',
            value: shipmentInfo.recipientMail,
        },
        reason: {
            name: 'reason',
            fieldType: 'enum',
            label: 'LBL_REASON',
            options: options,
        },
        message: {
            name: 'message',
            fieldType: 'textarea',
            value: '',
            label: 'LBL_CS_TEXT',
        },
        requestType: {
            name: 'requestType',
            value: 'question_om_box',
            hidden: true,
        },
    });

    /**
    * @param {string} value
    * @param {string} name
    */
    const changeValue = (value, name) => {
        setFields((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            },
        }));
    };

    /**
     *
     */
    const sendForm = async () => {
        if (!checkRequiredFields(fields, null, setFields)) {
            return;
        }

        const postData = {
            message: '',
            date: '',
            reason: '',
        };

        Object.keys(fields).forEach((key) => {
            const field = fields[key];
            postData[key] = field.value;
        });

        postData.message = postData.reason + '\n' + postData.message + utils.formatDate(postData.date, true);

        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};

        try {
            const response = await utils.post('request/create', postData, header);

            if (response?.status) {
                toast.success(utils.useTranslate('LBL_EXTEND_REQUEST_CREATED'));
                closePopup();
                window.open(`${window.location.protocol}//${window.location.host}/request/${response.data.requestHash}`, '_blank');
            } else {
                toast.error(utils.useTranslate('LBL_CS_REQUEST_WAS_NOT_CREATED'));
                closePopup();
            }
        } catch (error) {
            console.error(error);
            utils.handleCnTimeOut(error);
        }
    };

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                <div className='trackingForm'>
                    <CnForm loaded={true} fields={fields} onChange={changeValue} />
                </div>
            </div>

            <div className="popupButtons">
                <Button onClick={() => closePopup()} variant='light'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                </Button>
                <Button onClick={() => sendForm()} variant='primary'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                </Button>
            </div>
        </div>
    );
}
