/**
 *
 * @param {string} shipmentNr
 * @returns {string | null}
 */
export default function getRecipientZIP(shipmentNr) {
    const localStoragePackages = localStorage.getItem('mySpsPackages');
    if (localStoragePackages) {
        const localData = JSON.parse(localStoragePackages);
        return localData[shipmentNr];
    }

    return null;
}
