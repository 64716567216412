import React, {useCallback, useEffect, useState} from 'react';
import {Checkbox as CheckboxMui} from '@mui/joy';

interface CheckboxProps {
    label: string | React.ReactNode;
    checked: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
    disabled?: boolean;
    formik?: boolean;
    name?: string;
    link?: string;
    error?: boolean;
    helperText?: any;
    id?: string;
}

export default function Checkbox({
    label,
    checked,
    onChange,
    className,
    disabled,
    link,
    name,
    error,
    helperText,
    id,
}: CheckboxProps) {
    const [checkedState, setCheckedState] = useState(checked);

    useEffect(() => {
        setCheckedState(checked);
    }, [checked]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (disabled) {
            return;
        }
        const checked = event.target.checked;
        setCheckedState(checked);
        onChange(checked);
    }, [onChange, disabled]);

    return (
        <div className={`checkboxWrapper ${className} ${disabled ? 'disabled' : ''}`}>
            <CheckboxMui
                checked={checkedState}
                onChange={handleChange}
                variant="soft"
                className='checkboxInput'
                name={name}
                id={id}
            />
            {link ?
                <a href={link}><p className='checkboxLabel'>{label}</p></a> :
                <span className='checkboxLabel'>{label}</span>
            }
            {helperText && <p className={'helperText' + (error ? ' error' : '')}>{helperText}</p>}
        </div>
    );
}
