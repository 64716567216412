import {useState, useEffect} from 'react';
import Button from '@/components/formElements/Button';
import utils from '@/utils/Utils';
import toast from 'react-hot-toast';
import Select from '@/components/formElements/Select';
import CnForm from '@/helpers/tracking/CnForm';
import Sk from '@/components/formElements/Sk';
import Stepper from '@/components/formElements/Stepper';
import OtpInput from '@/components/formElements/OtpInput';
import Checkbox from '@/components/formElements/Checkbox';
import Loader from '@/components/Loader';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

export default function ChangePPDPopup({data, closePopup}: PopupProps) {
    const [paymentDocumentsData, setPaymentDocumentsData] = useState([]);
    const [paymentDocumentDetail, setPaymentDocumentDetail] = useState({zipNumber: '', bccn: ''});
    const [originalDocumentZip, setOriginalDocumentZip] = useState('');
    const [fields] = useState({
        shipmentNr: {name: 'shipmentNr', label: 'LBL_SHIPMENT_NR', required: true, hidden: true},
        zipNumber: {name: 'zipNumber', label: 'LBL_ZIP', required: true},
        documentNumber: {name: 'documentNumber', label: 'LBL_DOCUMENT_NUMBER', required: true, hidden: true},
        prijateod: {name: 'prijateod', label: 'LBL_RECEIVED_FROM', required: true},
        adresa: {name: 'adresa', label: 'LBL_ADDRESS', required: true},
        mesto: {name: 'mesto', label: 'LBL_CITY', required: true},
        icdph: {name: 'icdph', label: 'LBL_IC_DPH', required: true},
        email: {name: 'email', label: 'LBL_EMAIL', required: true},
    });
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [step, setStep] = useState(0);
    const [authCode, setAuthCode] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [confirmStatement, setConfirmStatement] = useState(false);
    const [statementError, setStatementError] = useState(null);
    const [load, setLoad] = useState(false);
    const shipmentInfo = data.shipmentInfo[0];

    /**
     *
     * @param {string} value
     * @param {string} name
     */
    const changeValue = (value, name) => {
        setPaymentDocumentDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        /**
         *
         */
        const getPaymentDocuments = async () => {
            const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};

            try {
                const response = await utils.post('tracking/getPaymentDocuments', {shipmentNr: shipmentInfo.shipmentNr}, header);

                if (response.status && response.data.length > 0) {
                    const documentNumbers = [];
                    response.data.forEach((document) => {
                        documentNumbers.push(document.cisloD);
                    });
                    setDocuments(documentNumbers);
                    setPaymentDocumentsData(response.data);
                    setSelectedDocument(response.data?.[0]?.cisloD);
                } else {
                    closePopup();
                    toast.error(utils.useTranslate(response.data?.errorData || 'LBL_NO_PAYMENT_DOCUMENTS'));
                }
            } catch (error) {
                console.error(error);
                closePopup();
                utils.handleCnTimeOut(error);
            }
        };

        getPaymentDocuments();
    }, [closePopup, shipmentInfo.shipmentNr]);

    useEffect(() => {
        /**
        *
        */
        const getPaymentDocumentDetails = async () => {
            if (!selectedDocument || step !== 0) {
                return;
            }
            const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
            const params = {
                shipmentNr: '',
                documentNumber: selectedDocument,
                zipNumber: '',
            };

            paymentDocumentsData.forEach((document) => {
                if (document.cisloD === selectedDocument) {
                    params.shipmentNr = document.bccn;
                    return params.zipNumber = document.psc;
                }
            });

            try {
                const response = await utils.post('tracking/getPaymentDocumentDetails', params, header);

                if (response.status) {
                    setPaymentDocumentDetail(
                        {...response.data,
                            ...response.data.additionalProperties,
                            documentNumber: response.data.cisloD,
                            zipNumber: response.data.psc,
                            shipmentNr: response.data.bccn,
                        });
                    setOriginalDocumentZip(response.data.psc);
                } else {
                    toast.error(utils.useTranslate(response.data?.errorData));
                }
            } catch (error) {
                console.error(error);
                closePopup();
                utils.handleCnTimeOut(error);
            }
        };

        getPaymentDocumentDetails();

    // Only when the selectedDocument is changed because
    // It makes another requests and it causes rewriting of the session for next requests
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDocument]);

    /**
     *
     */
    const requestPaymentDocumentChange = async () => {
        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
        const params = {};
        Object.keys(fields).map((key) => {
            params[key] = paymentDocumentDetail[key];
        });

        setLoad(true);
        try {
            const response = await utils.post('tracking/requestPaymentDocumentChange', params, header);

            if (response?.status) {
                if (response.data?.message === 'EMAILSENT') {
                    setStep(2);
                } else {
                    toast.error(utils.useTranslate('LBL_CHANGE_PPD_ERROR_ON_REQUEST') + `kód: ${response.data?.message}`);
                    closePopup();
                }
                setLoad(false);
            } else {
                toast.error(utils.useTranslate(response.data?.errorData));
                closePopup();
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
            utils.handleCnTimeOut(error);
        }
    };

    /**
     * @returns {function | void} setStatementError
     */
    const confirmPaymentDocumentChange = async () => {
        if (!confirmStatement) {
            return setStatementError('LBL_CHANGE_PPD_CHECKBOX_ERROR');
        } else {
            setStatementError('');
        }
        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
        const params = {
            shipmentNr: paymentDocumentDetail.bccn,
            zipNumber: originalDocumentZip,
            autentificationcode: authCode,
            documentNumber: selectedDocument,
        };

        setLoad(true);
        try {
            const response = await utils.post('tracking/confirmPaymentDocumentChange', params, header);

            if (response.status) {
                setStep(3);
                setDownloadUrl(`${utils.apiUrl}file/get/${response.data.fileHash}`);
            } else {
                toast.error(utils.useTranslate(response.data?.errorData));
            }
            setLoad(false);
        } catch (error) {
            setLoad(false);
            console.error(error);
            utils.handleCnTimeOut(error);
        }
    };

    /**
     *
     */
    const getPaymentDocumentsFiles = async () => { // recipientZip
        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
        const params = {
            shipmentNr: shipmentInfo.shipmentNr,
            zipNumber: utils.getRecipientZIP(shipmentInfo.shipmentNr),
        };

        setLoad(true);
        try {
            const response = await utils.post('tracking/getPaymentDocumentsFiles', params, header);

            if (response.status) {
                window.open(`${utils.apiUrl}file/get/${response.data.fileHash}`, '_blank');
                closePopup();
            } else {
                toast.error(utils.useTranslate(response.data?.errorData));
            }
            setLoad(false);
        } catch (error) {
            console.error(error);
            utils.handleCnTimeOut(error);
            setLoad(false);
        }
    };

    /**
     *
     */
    const pasteCodeFromClipboard = async () => {
        const text = await navigator.clipboard.readText();
        setAuthCode(text);
    };

    const stepperItems = [
        'LBL_PPD_STEP_1',
        'LBL_PPD_STEP_2',
        'LBL_PPD_STEP_3',
    ];

    return (
        <div className="popupContentWrapper infoPopup changePPDPopup">
            {step !== 0 ?
                <Stepper
                    activeStep={step}
                    items={stepperItems || []}
                    variant={'horizontal'}
                /> : null}
            {step === 0 ?
                <div className='router'>
                    <button onClick={() => getPaymentDocumentsFiles()} className='changePPDBtn'>
                        <span className='icon iconfas-download'/>
                        <span className='text'>{utils.useTranslate('LBL_DOWNLOAD_PPD_BTN')}</span>
                    </button>
                    <button onClick={() => setStep(1)} className='changePPDBtn'>
                        <span className='icon iconfas-arrowRightCircle'/>
                        <span className='text'>{utils.useTranslate('LBL_CHANGE_PPD_BTN')}</span>
                    </button>
                </div> :
                step === 1 ?
                    <>
                        <p className='cnPopupText'>{utils.useTranslate('Zde budou instrukce SPS dodá')}</p>
                        <div className="popupContent">
                            <div className='changePPDForm'>
                                <div className='documentSelect'>
                                    <Sk loaded={!!selectedDocument} className='skeleton' >
                                        <Select
                                            options={documents}
                                            onChange={setSelectedDocument}
                                            value={selectedDocument}
                                            label={utils.useTranslate('LBL_SELECT_PAYMENT_DOCUMENT')}
                                        />
                                    </Sk>
                                </div>
                                <div className='trackingForm'>
                                    <CnForm loaded={selectedDocument} onChange={changeValue} fields={fields} data={paymentDocumentDetail} />
                                </div>
                            </div>
                        </div>
                    </> : step === 2 ?
                        <>
                            <p className='cnPopupText center'>{utils.useTranslate('LBL_PPD_AUTH_MESSAGE')}</p>
                            <div className='changePPDAuth'>
                                <span className='iconfas-lock icon'/>
                                <OtpInput className='authInput' length={6} value={authCode} onChange={setAuthCode} />
                                <span className='iconfas-paste icon clickable' onClick={pasteCodeFromClipboard}/>
                            </div>
                            <div className='changePPDStatement'>
                                <div className='box'>
                                    <Checkbox
                                        className='RequestReplyCheckbox'
                                        label={utils.useTranslate('LBL_CHANGE_PPD_CHECKBOX_STATEMENT')}
                                        onChange={(value) => setConfirmStatement(value)}
                                        checked={confirmStatement}
                                    />
                                    <a className='changePPDLink' onClick={() => {
                                        window.open('https://sluzby.sps-sro.sk/doklady/assets/Stanovisko_editovanie_dokladov.pdf',
                                            '_blank');
                                    }}>
                                        <span className='iconfas-document' />{utils.useTranslate('LBL_CHANGE_PPD_CHECKBOX_LINK')}
                                    </a>
                                </div>
                                {statementError ? <div className='error'>{utils.useTranslate(statementError)}</div> : null}
                            </div>
                        </> :
                        <>
                            <p className='ppdDownloadText cnPopupText'>{utils.useTranslate('LBL_PPD_CHANGE_SUCCESS_MESSAGE')}
                                <span className='iconfas-success iconSuccess' />
                            </p>
                            <div className='downloadFile'>
                                <a className='iconfas-download icon' href={downloadUrl} target='blank' onClick={() => closePopup()}></a>
                            </div>
                        </>
            }
            {step !== 3 ?
                <div className="popupButtons">
                    <Button onClick={() => closePopup()} variant='light'>
                        <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                    </Button>
                    {step !== 0 ? <Button variant='primary' onClick={() => {
                        step === 1 ? requestPaymentDocumentChange() : confirmPaymentDocumentChange();
                    }}>
                        <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                    </Button> : null}
                </div> : <></>}
            {load ? <Loader /> : null}
        </div>
    );
}
