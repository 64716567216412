interface RawData {
    center: string;
    cod: number;
    customerId: string;
    flexibleDelivery: boolean;
    insurance: number;
    packageCount: string;
    packageNr: string;
    packageNrs: null | string[];
    payments: Array<{ [key: string]: any }>; // Assuming payments is an array of objects, replace `any` with actual type if known
    pin: boolean;
    recipient: string;
    recipientCenter: string;
    recipientCity: string;
    recipientMail: string;
    recipientPhone: string;
    recipientStreet: string;
    recipientZIP: string;
    ref1: null | string;
    ref2: string;
    ref3: string;
    ref4: string;
    refusableDelivery: boolean;
    returnableDelivery: boolean;
    sender: string;
    senderCenter: string;
    senderCity: string;
    senderMail: string;
    senderPhone: string;
    senderStreet: string;
    senderZIP: string;
    services: number[];
    shipmentNr: string;
    status: number;
    statusCode: string;
    statusDescr: string;
    statusText: string;
    statusTime: string;
    timestamp: string;
    weight: number;
  }

  interface RequestData {
    id: null | number;
    requestNumber: null | string;
    shipmentNumber: null | string;
    date: null | string;
    origin: null | string;
    messages: string[];
    status: null | string;
  }

  interface NewRequestData {
    shipmentNumber: string;
    date: null | string;
    message: string;
    email: string;
    attachment: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
  }

  interface Defs {
    menu: string[];
    footer: string[];
  }

  interface ShipmentInfo {
    packageNrs: string[];
    panels: { [key: string]: any }; // Define more specific type if available
    rawData: RawData;
  }

  type DataConstructors = {
    RequestData: RequestData;
    NewRequestData: NewRequestData;
    Defs: Defs;
    ShipmentInfo: ShipmentInfo;
  };

const dataConstructors: DataConstructors = {
    RequestData: {
        id: null,
        requestNumber: null,
        shipmentNumber: null,
        date: null,
        origin: null,
        messages: [],
        status: null,
    },
    NewRequestData: {
        shipmentNumber: '',
        date: null,
        message: '',
        email: '',
        attachment: '',
        firstName: '',
        lastName: '',
        phone: '',
    },
    Defs: {
        menu: [],
        footer: [],
    },
    ShipmentInfo: {
        packageNrs: [],
        panels: {},
        rawData: {} as RawData, // Ensure rawData is of type RawData
    },
};

export default dataConstructors;
