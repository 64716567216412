import MuiStepper from '@mui/material/Stepper';
import {Typography, Step, StepLabel, StepConnector, stepConnectorClasses, styled} from '@mui/material';
import {Orientation} from '@mui/material/Stepper';
import utils from '@/utils/Utils';

// Solution 1
const StepperSx = {
    '& .MuiStepConnector-root': {
        left: 'calc(-50% + 40px)',
        right: 'calc(50% + 40px)',
    },
    '& .MuiStepConnector-line': {
        marginTop: '34px',
    },
};

const Connector = styled(StepConnector)(({className}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        left: 'calc(-50% + 24px)',
        right: 'calc(50% + 24px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            // Changed because of issue https://gitlab.acmark.cz/coripo/mysps/-/issues/15 originally was dashed orange line on active step
            // borderColor: className !== 'vertical' ? '#F58220' : '',
            // borderStyle: className !== 'vertical' ? 'dashed' : '',
            borderLeft: className === 'vertical' ? '1px dashed #F58220' : '',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#F58220',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#a7a7a7',
        borderBottomWidth: 1,
        borderRadius: 1,
    },
}));

interface StepperProps {
    variant?: Orientation, // vertical
    activeStep: number,
    items: string[],
    id?: string,
    className?: string,
}

export default function Stepper({variant, activeStep, items = [], id, className}: StepperProps) {
    const isVertical = variant === 'vertical' ? true : false;

    /**
     *
     * @param {number} index
     * @param  {number} active
     * @returns {JSX}
     */
    const renderIcon = (index, active) => {
        let iconClass = null;
        // Can be active but changed because of issue https://gitlab.acmark.cz/coripo/mysps/-/issues/15
        index === active ? iconClass = 'inactive' : null;
        index < active ? iconClass = 'complete' : null;
        index > active ? iconClass = 'inactive' : null;

        return <div className={`${iconClass} icon iconfas-check`} />;
    };

    return (
        <MuiStepper
            activeStep={activeStep}
            alternativeLabel={!isVertical}
            sx={!isVertical ? StepperSx : {}}
            className={'stepper ' + className}
            orientation={variant}
            connector={<Connector className={variant}/>}
            id={id}
        >
            {items.map((label, index) => (
                <Step className='step' key={label}>
                    {!isVertical ? <Typography className='topLabel'>{utils.useTranslate(label)}</Typography> : null}
                    <StepLabel
                        className='bottomLabel'
                        StepIconComponent={() => renderIcon(index, activeStep)}
                    >
                        {isVertical ? utils.useTranslate(label) : null}
                    </StepLabel>
                </Step>
            ))}
        </MuiStepper>
    );
}
