import React, {useContext, Suspense} from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import AppContext from '@context/AppContext';
import Loader from '@components/Loader';

const NewRequestsComponent = React.lazy(() => import('@components/NewRequestsComponent'));

export default function NewRequest() {
    const {defs} = useContext(AppContext);

    return (
        <div className='pageContainer requestPage'>
            <Header menuItems={defs.menu} onLogin={() => {}} />

            <div className='pageContent'>
                <Suspense fallback={<Loader />}>
                    <NewRequestsComponent />
                </Suspense>
            </div>

            <Footer footerItems={[]} fromContext={true} />
        </div>
    );
}
