import {useState, useCallback} from 'react';
import Button from '@/components/formElements/Button';
import CnForm from '@/helpers/tracking/CnForm';
import utils from '@/utils/Utils';
import checkRequiredFields from '@/helpers/tracking/checkRequiredFields';
import {Badge} from '@mui/base/Badge';
import toast from 'react-hot-toast';
import Loader from '@/components/Loader';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

// TO DO create complaint to CRM
export default function ReportDamage({data, closePopup}: PopupProps) {
    const shipmentInfo = data.shipmentInfo[0];
    const [photos, setPhotos] = useState({
        photo_1: null,
        photo_2: null,
        photo_3: null,
        photo_4: null,
    });
    const [load, setLoad] = useState(false);
    const [fields, setFields] = useState({
        shipmentNr: {
            name: 'shipmentNumber',
            hidden: true,
            value: shipmentInfo.shipmentNr,
        },
        firstName: {
            name: 'firstName',
            label: 'LBL_FIRST_NAME',
            required: true,
        },
        lastName: {
            name: 'lastName',
            label: 'LBL_LAST_NAME',
            required: true,
        },
        phone: {
            name: 'phone',
            label: 'LBL_PHONE',
            required: true,
            value: shipmentInfo.recipientPhone,
        },
        email: {
            name: 'email',
            label: 'LBL_EMAIL',
            required: true,
            type: 'email',
            value: shipmentInfo.recipientMail,
        },
        complaintDescription: {
            name: 'complaintDescription',
            label: 'LBL_ISSUE_DESCRIPTION',
            required: true,
            fieldType: 'textarea',
        },
        itemDescription: {
            name: 'itemDescription',
            label: 'LBL_SHIPMENT_DESCRIPTION',
            required: true,
            fieldType: 'textarea',
        },
    });

    /**
    * @param {string} value
    * @param {string} name
    */
    const changeValue = (value, name) => {
        setFields((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            },
        }));
    };

    /**
     *
     */
    const reportDamage = async () => {
        if (!checkRequiredFields(fields, null, setFields) || load) {
            return;
        }

        if (!photos.photo_1 && !photos.photo_2 && !photos.photo_3 && !photos.photo_4) {
            toast.error(utils.useTranslate('LBL_UPLOAD_ET_LEAST_ONE_PHOTO'));
            return;
        }

        setLoad(true);
        const postData = {
            type: 'ps',
        };

        Object.keys(fields).forEach((key) => {
            const field = fields[key];
            postData[key] = field.value;
        });

        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};

        try {
            const response = await utils.post('complaint/create', {...postData, ...photos}, header);

            if (response.status) {
                closePopup();
                toast.success(utils.useTranslate('LBL_SUCCESS_CREATE_COMPLAINT'));
                setLoad(false);
            } else {
                closePopup();
                toast.error(utils.useTranslate('LBL_ERROR_CREATE_COMPLAINT'));
                setLoad(false);
            }
        } catch (error) {
            console.error('error', error);
            setLoad(false);
        }
    };

    /**
     * handleFileUpload - handle file upload
     * @description
     * 1. validate file size (max 10MB)
     * 2. validate file type (only images are allowed)
     * 3. upload file
     * 4. show error if something went wrong
     * 5. show success message if everything is ok
     * 6. set attachment name
     * @param {object} event - file upload event
     * @returns {Promise}
     */
    const handleFileUpload = useCallback(async (event) => {
        if (event.target.files.length <= 0) {
            return;
        }

        if (event.target.files.length > 4) {
            toast.error(utils.useTranslate('LBL_COMPLAINT_PHOTOS_MAX_4'));
            return;
        }

        // Validate
        Array.from(event.target.files).forEach(async (file: File) => {
            // validate file size (max 10MB)
            if (file && file.size > 10485760) {
                toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_TOO_BIG'));
                return;
            }

            // validate file type (only images or pdf,doc,docx,xls,xlsx,zip are allowed)
            if (file && !file.type.match(/image\/(png|jpg|jpeg|gif|bmp)/)) {
                toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_INVALID'));
                // setRequestData({...requestData, attachment: null});
                return;
            }
        });

        const uploadedPhotos = {
            photo_1: null,
            photo_2: null,
            photo_3: null,
            photo_4: null,
        };

        Array.from(event.target.files).forEach(async (file :File, i) => {
            // upload file
            if (file) {
                const fileData = new FormData();
                fileData.append('file', file, file.name);

                setLoad(true);
                const response = await utils.upload('file/upload', fileData).catch((error) => {
                    console.error('error', error);
                    toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_ERROR'));
                });

                if (response.status === true && response.data?.fileName) {
                    toast.success(utils.useTranslate('LBL_REQUEST_CREATE_FILE_SUCCESS'));
                    // setPhotos({...photos, [`photo_${i + 1}`]: response.data?.fileName});
                    uploadedPhotos[`photo_${i + 1}`] = response.data?.fileName;
                    // setRequestData({...requestData, attachment: response.data?.fileName});
                    setLoad(false);
                } else {
                    toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_ERROR'));
                    setLoad(false);
                    // setRequestData({...requestData, attachment: null});
                    return;
                }

                setPhotos({...photos, ...uploadedPhotos});
            }
        });
    }, [photos, setPhotos]);

    return (
        <div className="popupContentWrapper infoPopup">
            <p className='reportDamageText'>{utils.useTranslate('LBL_REPORT_DAMAGE_TEXT')}</p>
            <div className="popupContent">
                <div className='trackingForm'>
                    <CnForm loaded={true} fields={fields} onChange={changeValue}/>
                    <Badge badgeContent={null}>
                        <Button
                            className='RequestUploadFileButton'
                            variant="light"
                            id='uploadFileBtn'
                            component="label"
                            version="material"
                        >
                            <span className='iconfas-upload' /> {utils.useTranslate('LBL_BTN_UPLOAD_FILE')}
                            <input
                                type='file'
                                multiple
                                accept="image/png, image/gif, image/jpeg, image/jpg"
                                className='RequestUploadFileInput hiddenInput'
                                onChange={handleFileUpload}
                            />
                        </Button>
                    </Badge>
                </div>
            </div>

            <div className="popupButtons">
                <Button onClick={() => closePopup()} variant='light'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                </Button>
                <Button onClick={() => reportDamage()} variant='primary'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                </Button>
            </div>
            {load ? <Loader /> : null}
        </div>
    );
}
