import {Popup} from 'react-leaflet';
import utils from '@/utils/Utils';
import Button from '../../formElements/Button';

type ParcelShop = {
    description: string;
    city: string;
    address: string;
    zip: string;
    workdays: string;
}

interface MapPopupProps {
    parcelShop: ParcelShop,
    onSelect: (street, city, zip) => void;
}

export default function MapPopup({parcelShop, onSelect} :MapPopupProps) {
    const workDays = JSON.parse(parcelShop.workdays);

    return (
        <Popup className='markerPopup'>
            <div className='content'>
                <div className='header bold'>{parcelShop.description}</div>
                <div className='info'>
                    <div>
                        <div className='bold'>{utils.useTranslate('LBL_ADDRESS_OF_PARCEL_SHOP')}</div>
                        <div>
                            <b>{utils.useTranslate('LBL_CITY')}: </b>
                            <span> {parcelShop.city}</span>
                        </div>
                        <div>
                            <b>{utils.useTranslate('LBL_ADDRESS')}:</b>
                            <span> {parcelShop.address}</span>
                        </div>
                        <div>
                            <b>{utils.useTranslate('LBL_ZIP')}: </b>
                            <span> {parcelShop.zip}</span>
                        </div>
                    </div>
                    <div>
                        <div className='bold'>{utils.useTranslate('LBL_OPEN_TIME')}</div>
                        {workDays.map((day, i) => {
                            if (i <='6') {
                                return (
                                    <div key={day.date}>
                                        <b>{utils.formatDate(day.date, true)} </b>
                                        <span>{day.workHours}</span>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <Button
                    onClick={() => onSelect(parcelShop.address, parcelShop.city, parcelShop.zip)}
                    variant='primary'
                    className='selectButton'>
                    {utils.useTranslate('LBL_SELECT')}
                </Button>
            </div>
        </Popup>
    );
}
