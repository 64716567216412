import moment from 'moment';

/**
 * getDate
 * @param {string} date
 * @param {boolean} withTime
 * @param {boolean} isUTC
 * @returns {string}
 */
export default function getDate(date: string = null, withTime: boolean = true, isUTC: boolean = true) {
    // if isUTC convert date from UTC to local time
    if (date && isUTC) {
        date = moment.utc(date).local().format();
    }

    let format = 'DD. MM. YYYY';
    if (withTime) {
        format = 'HH:mm DD. MM. YYYY';
    }

    if (date) {
        return moment(date).format(format);
    }
    return moment().format(format);
}
