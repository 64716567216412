import {useEffect, useState} from 'react';
import deliveryVan from '@img/delivery-van-svgrepo-com.svg';

export default function Loader() {
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        setShowLoader(true);

        return () => {
            setShowLoader(false);
        };
    }, []);

    return (
        <div className={'loader' + (showLoader ? ' show' : '')}>
            <div className="loader-car">
                <div className="loader-child">
                    <img src={deliveryVan} alt="Delivery Van" className='loader-van' />
                    <span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </div>
            </div>
        </div>
    );
}
