import {useMemo} from 'react';
import Button from '@components/formElements/Button';
import utils from 'utils';

interface PopupProps {
    id?: string;
    message: string;
    buttons: PopupButton[];
    type?: 'success' | 'error' | 'info';
}

export default function InfoPopup({message, buttons, type}: PopupProps) {
    const buttonsDef = useMemo(() => {
        return buttons && [...buttons].reverse();
    }, [buttons]);

    const popupIcon = useMemo(() => {
        let icon = null;
        switch (type) {
            case 'success':
                icon = 'iconfas-success';
                break;
            case 'error':
                icon = 'iconfas-error';
                break;
            case 'info':
                icon = 'iconfa-info';
        }

        return icon && <span className={`popupIcon ${icon}`} />;
    }, [type]);

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                {popupIcon}
                <p className='popupMessage'>{message}</p>
            </div>

            <div className="popupButtons">
                {buttonsDef && buttonsDef.map((button, index) => (
                    <Button
                        key={index}
                        className={`popupButton ${button.type}`}
                        onClick={button.action}
                    >
                        {utils.useTranslate(button.label)}
                    </Button>
                ))}
            </div>
        </div>
    );
}
