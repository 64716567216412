import utils from '@/utils/Utils';
import userIcon from '@img/userIcon.png';

interface UserLoginProps {
    onLogin: () => void;
}

export default function UserLogin({onLogin} :UserLoginProps) {
    return (
        <div className='user' onClick={onLogin}>
            <img src={userIcon}
                alt='user icon'
                className='headerAvatar'
            />
            <div className='username bold'>
                {utils.useTranslate('LBL_LOGIN')}
            </div>
        </div>
    );
}
