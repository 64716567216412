import {Button as MuiButton} from '@mui/base/Button';
import {Button as MaterialButton} from '@mui/material';
import React from 'react';

interface ButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    link?: string;
    variant?: 'primary' | 'dark' | 'light' | 'disabled';
    id?: string;
    component?: any;
    version?: string;
}

export default function Button({children, className, onClick, disabled, link, variant, id, component, version}: ButtonProps) {
    if (link) {
        return (
            <MuiButton
                className={`baseButton ${className} ${variant ? variant : ''}`}
                href={link}
                disabled={disabled}
                id={id}
            >
                {children}
            </MuiButton>
        );
    }

    if (version === 'material') {
        return (
            <MaterialButton
                className={`baseButton ${className} ${variant ? variant : ''}`}
                disabled={disabled}
                onClick={onClick}
                id={id}
                component={component}
            >
                {children}
            </MaterialButton>
        );
    }

    return (
        <MuiButton
            className={`baseButton ${className} ${variant ? variant : ''}`}
            disabled={disabled}
            onClick={onClick}
            id={id}
        >
            {children}
        </MuiButton>
    );
}
