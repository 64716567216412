import {useState} from 'react';
import Button from '@/components/formElements/Button';
import utils from '@/utils/Utils';
import toast from 'react-hot-toast';
import Loader from '@/components/Loader';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

export default function ResendCustomerPinPopup({data, closePopup}: PopupProps) {
    const shipmentInfo = data.shipmentInfo[0];

    const [contactData, setContactData] = useState({email: '', phone: ''});
    const [load, setLoad] = useState(false);

    /**
     * @returns {function}
     */
    const resendCustomerPIN = async () => {
        if (load) {
            return;
        }
        setLoad(true);
        const postData = {
            shipmentNr: shipmentInfo.shipmentNr,
            recipientPhone: shipmentInfo.recipientPhone,
            recipientMail: shipmentInfo.recipientMail,
        };

        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
        const response = await utils.post('tracking/resendCustomerPIN', postData, header)
            .catch((error) => {
                console.error(error);
                utils.handleCnTimeOut(error);
                setLoad(false);
            });

        if (response) {
            if (response?.status) {
                // toast.success(utils.useTranslate('LBL_RESEND_PIN_SUCCESS'));
                setContactData({email: response.data?.recipientMail, phone: response.data?.recipientPhone});
            } else {
                toast.error(utils.useTranslate(response.data?.errorData));
                closePopup();
            }
            setLoad(false);
        }
    };

    /**
     *
     * @param {string} email
     * @returns {string}
     */
    const maskEmail = (email) => {
        const [localPart, domain] = email.split('@');
        const maskedLocal = localPart.length > 2 ?
            localPart[0] + '*'.repeat(localPart.length - 2) + localPart.slice(-1) :
            localPart[0] + '*';
        const [domainName, domainExtension] = domain.split('.');
        const maskedDomain = domainName[0] + '*'.repeat(domainName.length - 1) + '.' + domainExtension;
        return maskedLocal + '@' + maskedDomain;
    };

    /**
     *
     * @param {string} phoneNumber
     * @returns {string}
     */
    const maskPhoneNumber = (phoneNumber) => {
        phoneNumber = phoneNumber.toString();

        if (phoneNumber.length <= 3) {
            return phoneNumber;
        }

        const partToMask = phoneNumber.slice(0, -3);
        const lastThreeDigits = phoneNumber.slice(-3);
        const maskedPart = partToMask.replace(/./g, '*');

        return maskedPart + lastThreeDigits;
    };

    /**
     * @returns {JSX}
     */
    const renderSuccessMessage = () => {
        return (
            <>
                <h4 className='header'>{utils.useTranslate('LBL_PIN_WAS_SENDED')}</h4>
                <div className='icon iconfas-success' />
                {contactData.phone && <div>{maskPhoneNumber(contactData.phone)}</div>}
                {contactData.email && <div>{maskEmail(contactData.email)}</div>}
            </>
        );
    };

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                {!contactData.email && !contactData.phone ?
                    <div className='popupMessage'>
                        {utils.useTranslate('LBL_CONFIRM_RESEND_CUSTOMER_PIN')}
                    </div> :
                    <div className='pinSuccess'>{renderSuccessMessage()}</div>}
            </div>

            <div className="popupButtons">
                {!contactData.email && !contactData.phone ?
                    <>
                        <Button onClick={() => closePopup()} variant='light'>
                            <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                        </Button>
                        <Button onClick={() => resendCustomerPIN()} variant='primary'>
                            <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                        </Button>
                    </> :
                    <Button onClick={() => closePopup()} variant='primary'>
                        <span className='tabLabel bold'>{utils.useTranslate('LBL_CLOSE')}</span>
                    </Button>}
            </div>
            {load && <Loader />}
        </div>
    );
}
