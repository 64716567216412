/**
 * Fetches data from the mySPS rest API
 * @param {string} path
 * @param {boolean} returnOnlyData
 * @param {string} customToken
 * @returns Promise
 */
export default async function fetch(path: string, returnOnlyData: boolean = true, customToken: string = '') {
    const apiKey = '6VS314WZ+YtFPrYqqV2EZAoxKJNIyr13JGKTWjlOy6jz5zWKr-C8ZnpCJ7ChvZfW';
    const apiUrl = this.config?.api;

    const headers = {
        'Api-Key': apiKey,
        'Content-Type': 'application/json',
    };

    if (customToken !== '') {
        headers['Authorization'] = customToken;
    }

    const response = await this.rest.get(apiUrl + path, headers);

    // if 404 return null
    if (response.code === 404) {
        console.error('404: ' + response.message);
        return null;
    }

    // if status is not true, throw error
    // call .error() to show error message
    if (!response.status) {
        this.error(response.message);
    }

    if (!returnOnlyData) {
        return response;
    }

    return response?.data;
}
