import {ErrorInfo, useState} from 'react';
import Button from '@components/formElements/Button';

// error UI component
type Props = {
    error: Error;
    info: ErrorInfo
};

export default function Error404Component(props: Props) {
    const [state, setState] = useState({
        showDetail: false,
    });

    /**
     * handleReload
     */
    const handleReload = () => {
        window.location.href = '/';
    };

    // TODO: fix request 404 no error!

    return (
        <div className='errorComponent'>
            <div className='errorComponentContainer'>
                <h1 className='errorComponentTitle'
                    onClick={() => setState({showDetail: !state.showDetail})}>
                    Vyzerá že táto stránka neexistuje.
                </h1>
                <p className='errorComponentMessage'>
                    Skúste sa vrátiť na domácu stránku.
                </p>

                {state.showDetail &&
                    <div className='errorComponentDetails'>
                        <p className='errorComponentMessageStack'>{props.error.message}</p>
                        <p className='errorComponentMessageStack'>{props.info.componentStack}</p>
                    </div>
                }
            </div>
            <h2 className='error404Bg'>404</h2>

            <Button
                className='errorComponentButton'
                onClick={handleReload}
                variant='primary'
            >
                <span className='iconfa-refresh' /> Zpatky domov
            </Button>

        </div>
    );
}
