import {MuiOtpInput} from 'mui-one-time-password-input';

interface InputProps {
    className?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
    id?: string;
    label?: string;
    length?: number;
}

export default function Input({
    className = '',
    value,
    defaultValue,
    onChange,
    id,
    label,
    length,
}: InputProps) {
    return (
        <div className='InputWrapper'>
            {label && <label className='formElementLabel' htmlFor={id}>{label}</label>}
            <MuiOtpInput
                className={`otpInput ${className}`}
                value={value}
                defaultValue={defaultValue}
                onChange={(value: string) => onChange && onChange(value)}
                id={id}
                length={length || 4}
            />
        </div>
    );
}
