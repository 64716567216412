import {Component, ErrorInfo, ReactNode} from 'react';
import ErrorComponent from './ErrorComponent';

interface Props {
    children?: ReactNode;
}

interface State {
    hasError: boolean;
    error: Error;
    info: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: new Error(),
            info: {componentStack: ''},
        };
    }

    /**
     * getDerivedStateFromError
     * @returns {State}
     */
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }

    /**
     * componentDidCatch
     * @param {Error} error
     * @param {ErrorInfo} errorInfo
     */
    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('ErrorBoundary >> Uncaught error:', error, errorInfo);
        this.setState({
            hasError: true,
            error: error,
            info: errorInfo,
        });
    }

    public render() {
        const {hasError, error, info} = this.state;
        const {children} = this.props;

        return hasError ? <ErrorComponent error={error} info={info} /> : children;
    }
}

export default ErrorBoundary;
