import React, {useContext, Suspense, useState} from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import AppContext from '@context/AppContext';
import Loader from '@components/Loader';
import Error404Component from '@/components/Error/Error404Component';

const TrackingComponent = React.lazy(() => import('@components/TrackingComponent'));

export default function Tracking() {
    const {defs} = useContext(AppContext);
    const [notFound, setNotFound] = useState(false);

    if (notFound) {
        return <Error404Component info={{}} error={{name: 'Wrong package number', message: ''}} />;
    }

    return (
        <div className='pageContainer'>
            <Header menuItems={defs.menu} onLogin={() => {}} />

            <div className='pageContent'>
                <Suspense fallback={<Loader />}>
                    <TrackingComponent setNotFound={setNotFound} />
                </Suspense>
            </div>

            <Footer footerItems={[]} fromContext={true} />
        </div>
    );
}
