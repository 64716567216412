import {useQuery} from '@tanstack/react-query';

/**
 * useLoadLangs hook
 * @param {string} loadLang
 * @returns {void}
 */
export default function useLoadLangs() {
    /**
     * queryData - fetch data from API
     * @returns {Promise}
     */
    const queryLang = async () => {
        const language = localStorage.getItem('mySPS_language');
        if (!language) {
            localStorage.setItem('mySPS_language', 'sk');
        }
        const response = await this.fetch(`lang/get/${language}`);
        return response;
    };

    this.lang = useQuery({queryKey: ['lang'], queryFn: queryLang});

    if (this.lang.isError && !this.lang.isLoading) {
        console.error('useLoadLangs >> lang is unable to load', this.lang);
        throw new Error(this.lang.error.message);
    }
}
