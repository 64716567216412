import {Radio as RadioJoy} from '@mui/joy';

interface RadioProps {
    checked: boolean;
    onChange: () => void;
    value: any;
    name?: string;
    label?: string;
}
export default function Radio({checked, onChange, value, name, label} :RadioProps) {
    return (
        <div className='radioWrapper'>
            {label && <span className='label'>{label}</span>}
            <RadioJoy
                checked={checked}
                onChange={onChange}
                value={value}
                name={name}
            />
        </div>
    );
}
