import {createBrowserRouter} from 'react-router-dom';
import App from '@/App.tsx';
import Request from '@pages/Request';
import NewRequest from '@pages/NewRequest';
import Tracking from '@pages/Tracking';
import AppChild from '@/appChild';
import MainPage from '@pages/MainPage';
// import AdvancedTracking from '@pages/AdvancedTracking';
import NewRequestInternal from '@pages/NewRequestInternal';
import {InternalContextProvider} from '@context/InternalContext';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App><MainPage /></App>,
    },
    {
        path: '/tracking',
        element: <App><MainPage /></App>,
    },
    {
        path: '/tracking/:shipmentNr',
        element: <App><Tracking /></App>,
    },
    // Zatím tam v podstatě nic není takže zakomentovavam
    // {
    //     path: '/advancedTracking',
    //     element: <App><AdvancedTracking/></App>,
    // },
    {
        path: '/request',
        element: <App><NewRequest /></App>,
    },
    {
        path: '/request/:id',
        element: <App><Request /></App>,
    },
    {
        path: '/test',
        element: <App><AppChild/></App>,
    },
    {
        path: '/internal/request',
        element: (
            <App>
                <InternalContextProvider>
                    <NewRequestInternal />
                </InternalContextProvider>
            </App>
        ),
    },
]);

export default router;
