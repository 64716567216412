import React, {useContext, Suspense, useState, useEffect} from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import AppContext from '@context/AppContext';
import InternalContext from '@context/InternalContext';
import Loader from '@components/Loader';
import InternalLogin from '@components/InternalLogin';
import utils from 'utils';

const NewRequestsComponent = React.lazy(() => import('@components/NewRequestsComponent'));

export default function NewRequestInternal() {
    const {defs} = useContext(AppContext);
    const {internalUser, internalUserToken} = useContext(InternalContext);
    const loaded = utils.useTranslate().isLangLoaded();
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        if (internalUserToken && internalUserToken !== '') {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
    }, [internalUserToken, internalUser]);

    if (!loaded) {
        return <Loader />;
    }

    return (
        <div className='pageContainer requestPage'>
            <Header menuItems={defs.menu} onLogin={() => {}} />

            {!loggedIn ? (
                <div className='pageContent'>
                    <InternalLogin />
                </div>
            ) : (
                <div className='pageContent'>
                    <Suspense fallback={<Loader />}>
                        <NewRequestsComponent
                            internal={true}
                            internalToken={internalUserToken}
                            predefinedFields={internalUser.email ? {
                                email: internalUser.email,
                            } : null}
                        />
                    </Suspense>
                </div>
            )}

            <Footer footerItems={[]} fromContext={true} />
        </div>
    );
}
