import {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {Skeleton} from '@mui/material';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import AppContext from '@context/AppContext';
import ContactHeader from './ContactHeader';
import utils from 'utils';
import MobileNavBar from './MobileNav';
import UserLogin from './Parts/Header/UserLogin';

import logo from '@img/SPS_Logo_Color.png';
import userIcon from '@img/userIcon.png';
import spsLogoIcon from '@img/SpsLogoIconDark.svg';

interface HeaderProps {
    menuItems: MenuItem[];
    onLogin: () => void;
}

export default function Header({menuItems, onLogin}: HeaderProps) {
    const {logOut, user} = useContext(AppContext);
    const [openMobileNav, setOpenMobileNav] = useState(false);

    return (
        <>
            <div className='headerWrapper'>
                <ContactHeader />
                {/* <div className='headerLogoBox'>
                <Link to='/'>
                    <img src={logo}
                        alt='mySPS Logo'
                        className='headerLogo'
                    />
                </Link>
            </div> */}
                <div className='navbarWrapper'>
                    <div className='headerNavBar'>
                        <div className='headerNavBarLeft'>
                            <Link to='/' className='headerHomeButton bold'>
                                <img src={logo}
                                    alt='mySPS Logo'
                                    className='headerLogo'
                                />
                        mySPS
                            </Link>
                        </div>

                        <div className='headerNavBarRight'>
                            {menuItems.filter((item: MenuItem) => item.type !== 'icon').map((item, index) => {
                                if (user?.type !== 'internal' && item.type === 'internal') {
                                    return;
                                }
                                return (
                                    <Link
                                        key={index}
                                        to={item.link}
                                        className={'bold headerNavBarItem' + (item.active ? ' active' : '')}
                                    >
                                        {utils.useTranslate(item.label)}
                                    </Link>
                                );
                            })}

                            {menuItems.length === 0 && (
                                <>
                                    <Skeleton variant="rectangular" width={125} height={25} className='headerNavBarItem' />
                                    <Skeleton variant="rectangular" width={125} height={25} className='headerNavBarItem' />
                                    <Skeleton variant="rectangular" width={125} height={25} className='headerNavBarItem' />
                                    <Skeleton variant="rectangular" width={125} height={25} className='headerNavBarItem' />
                                </>
                            )}

                            {!user?.name ?
                                <UserLogin onLogin={onLogin}/> : (
                                    <Dropdown>
                                        <MenuButton className='user'>
                                            <img src={userIcon}
                                                alt='user icon'
                                                className='headerAvatar'
                                            />
                                            <div className='username bold'>
                                                {user?.name ? user?.name : utils.useTranslate('LBL_LOGIN')}
                                                {user?.name && user?.type === 'internal' && (
                                                    <img src={spsLogoIcon} alt='SPS Logo' className='spsLogoIconInternal' />
                                                )}
                                            </div>
                                        </MenuButton>
                                        <Menu size="sm">
                                            {/* <MenuItem>Profile</MenuItem>
                                    <MenuItem>My account</MenuItem> */}
                                            <MenuItem onClick={logOut}>{utils.useTranslate('LBL_LOGOUT')}</MenuItem>
                                        </Menu>
                                    </Dropdown>
                                )}
                        </div>
                    </div>
                    <div
                        onClick={() => setOpenMobileNav(!openMobileNav)}
                        className={`mobileHeaderMenu ${openMobileNav ? 'iconfas-cross' : 'iconfas-menu'}`}
                    />
                </div>
            </div>
            {openMobileNav && <MobileNavBar onLogin={onLogin} menuItems={menuItems} />}
        </>
    );
}
