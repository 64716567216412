import React from 'react';
import PropTypes from 'prop-types';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Toaster} from 'react-hot-toast';
import ErrorBoundary from './components/Error/ErrorBoundary';
import {AppContextProvider} from './context/AppContext';
import PopupsWrapper from '@components/Popup/PopupsWrapper';

const queryClient = new QueryClient();

export default function App(props) {
    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    {/* render wrapped component */}
                    {/* {props.children} */}
                    {React.cloneElement(props.children, {queryClient: queryClient})}

                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                    <Toaster
                        // position='top-right'
                        position='top-center'
                        containerClassName='toasterContainer'
                        toastOptions={{
                            className: 'toasterClass',
                            duration: 5000,
                            style: {
                                background: '#fff',
                                color: '#43515A',
                            },
                            success: {
                                className: 'toasterClass success',
                            },
                            error: {
                                className: 'toasterClass error',
                                duration: 8000,
                            },
                        }}
                        gutter={14}
                    />
                    <PopupsWrapper />
                </AppContextProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
}

App.propTypes = {
    children: PropTypes.node.isRequired,
};
