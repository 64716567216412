import React from 'react';
import {Select as SelectBase} from '@mui/joy';
import {selectClasses} from '@mui/joy/Select';
import Option from '@mui/joy/Option';

type SelectOption = {
    value: string;
    label: string;
};

interface SelectProps {
    className?: string;
    options: readonly SelectOption[];
    value?: string;
    name?: string;
    disabled?: boolean;
    defaultValue?: string;
    onChange: (value: string | number | null) => void;
    id?: string;
    placeholder?: string;
    variant?: 'primary' | 'form';
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    helperText?: React.ReactNode;
    error?: boolean;
    label?: string;
    required?: boolean;
}

export default function Select({
    className,
    options,
    value,
    disabled,
    defaultValue,
    onChange,
    id,
    variant,
    placeholder,
    onBlur,
    helperText,
    error,
    label,
    required,
}: SelectProps) {
    /**
     * @returns {JSX.Element | null}
     */
    const renderOptions = () => {
        if (!options) {
            return null;
        }
        if (!Array.isArray(options)) {
            return Object.keys(options).map((value) => {
                const label = options[value];
                return (
                    <Option key={value} value={value}>{label}</Option>
                );
            });
        } else {
            return options.map((value) => {
                return (
                    <Option key={value} value={value}>{value}</Option>
                );
            });
        }
    };

    return (
        <div className='SelectWrapper'>
            {label && (
                <label className='formElementLabel'>
                    {label}
                    {required && <span className='required'>*</span>}
                </label>
            )}
            <SelectBase
                placeholder={placeholder}
                indicator={<span className="iconfas-arrow-down" />}
                className={`Select ${variant} ${className}`}
                id={id}
                value={value}
                defaultValue={defaultValue}
                error={error}
                onChange={(_event, newValue) => onChange(newValue)}
                disabled={disabled}
                onBlur={onBlur}
                required={required}
                multiple={false}
                component={null}
                sx={{
                    [`& .${selectClasses.indicator}`]: {
                        transition: '0.2s',
                        [`&.${selectClasses.expanded}`]: {
                            transform: 'rotate(-180deg)',
                        },
                    },
                }}
            >
                {renderOptions()}
            </SelectBase>
            {helperText && <p className={'helperText' + (error ? ' error' : '')}>{helperText}</p>}
        </div>
    );
}
