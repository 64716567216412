import {useState, useContext} from 'react';
import Button from '@/components/formElements/Button';
import Loader from '@/components/Loader';
import utils from '@/utils/Utils';
import Select from '@/components/formElements/Select';
import formatDate from '@/utils/formatDate';
import toast from 'react-hot-toast';
import AppContext from '@/context/AppContext';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

export default function RejectShipmentPopup({data, closePopup}: PopupProps) {
    const shipmentInfo = data.shipmentInfo[0];
    const {castPopup} = useContext(AppContext);

    const [load, setLoad] = useState(false);
    const [value, setValue] = useState('');

    const options = utils?.lang?.data?.reject_reason;

    /**
     *
     */
    const rejectShipment = async () => {
        if (!value) {
            castPopup({
                title: utils.useTranslate('LBL_REQUIRED_FIELDS'),
                message: utils.useTranslate('LBL_SELECT_REASON_REJECT'),
                type: 'error',
            });
            return;
        }

        castPopup({
            title: utils.useTranslate('LBL_REJECT_SHIPMENT_CONFIRM_TITLE'),
            message: utils.useTranslate('LBL_REJECT_SHIPMENT_CONFIRM_MESSAGE'),
            type: 'confirm',
            onClose: () => {
                closePopup();
            },
            onConfirm: async () => {
                closePopup();
                setLoad(true);
                const requestName = shipmentInfo.packageNrs ? 'tracking/uploadTifMultiple' : 'tracking/uploadTif';

                const postData = {
                    shipmentNr: shipmentInfo.shipmentNr,
                    text: 'REFU' + formatDate(new Date()) + 'mySPS' + options[value],
                    eventType: 100,
                    center: shipmentInfo.recipientCenter,
                    packages: shipmentInfo.packageNrs ? shipmentInfo.packageNrs?.split(',') : [],
                };

                const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
                const response = await utils.post(requestName, postData, header)
                    .catch((error) => {
                        console.error(error);
                        utils.handleCnTimeOut(error);
                        setLoad(false);
                    });

                if (response) {
                    if (response?.status) {
                        toast.success(utils.useTranslate('LBL_REJECT_SHIPMENT_SUCCESS'));
                    } else {
                        toast.error(utils.useTranslate(response.data?.errorData));
                    }
                    setLoad(false);
                    closePopup();
                }
            },
        });
    };

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                <div className='trackingActionsBasicForm'>
                    <Select
                        value={value}
                        required={true}
                        onChange={(value :string) => setValue(value)}
                        options={options}
                        placeholder={utils.useTranslate('LBL_SELECT_REJECT_REASON')}
                        label={utils.useTranslate('LBL_REJECT_REASON')}
                    />
                </div>
            </div>

            <div className="popupButtons">
                <Button onClick={() => closePopup()} variant='light'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                </Button>
                <Button onClick={() => rejectShipment()} variant='primary'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                </Button>
            </div>
            {load && <Loader />}
        </div>
    );
}
