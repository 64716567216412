// test component for POC
import {useState, useEffect} from 'react';
import ReactLogo from '@img/react.svg';
import ViteLogo from '/vite.svg';

import utils from 'utils';
import {useQuery, QueryClient, useMutation} from '@tanstack/react-query';
import toast from 'react-hot-toast';

export default function AppChild() {
    const [count, setCount] = useState(0);

    const queryClient = new QueryClient();

    /**
     * queryData - fetch data from API
     * @returns {Promise}
     */
    const queryData = async () => {
        const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        return response.json();
    };

    const postTodo = async (todo) => {
        console.log('postTodo', todo);
    };

    const query = useQuery({queryKey: ['test'], queryFn: queryData});

    /**
     * callError
     * @returns {void}
     * @throws {Error}
     */
    const callError = () => {
        throw new Error('Error thrown from callError()');
    };

    useEffect(() => {
        console.log('AppChild mounted');
        setTimeout(() => {
            console.log('AppChild mounted - setTimeout');
            // devalidate test query
            queryClient.invalidateQueries({queryKey: ['test']});

            toast('Here is your toast.');
            toast.error('Here is your toast. error')
            toast.success('Here is your toast. success')
            toast.success((t) => (
                <span>
                    Custom and <b>bold</b>
                    <button onClick={() => toast.dismiss(t.id)}>Dismiss</button>
                </span>
            ))
            
        }, 4000);
    });

    const mutation = useMutation({
        mutationFn: postTodo,
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({queryKey: ['test']});
        },
    });

    return (
        <>
            <div>
                <a href="https://vitejs.dev" target="_blank" rel="noreferrer">
                    <img src={ViteLogo} className="logo" alt="Vite logo" />
                </a>
                <a href="https://react.dev" target="_blank" rel="noreferrer">
                    <img src={ReactLogo} className="logo" alt="React logo" />
                </a>
            </div>
            <h1>Vite + React</h1>
            <div className="card">
                <button onClick={() => setCount((count) => count + 1)}>
                    count is {count}
                </button>
                <p>
                    Edit <code>src/App.tsx</code> and save to test HMR<br/>
                    Home <span className='iconfas-home'></span>
                    <br/>
                    <br/>
                    {/* <span className='iconfas-user' onClick={() => setUsername('You!')}></span> {username} */}
                </p>
                <br/>
                <br/>
                <div onClick={callError}>
                    <span className='iconfas-extriangle'></span> Panic Button! <span className='iconfas-extriangle'></span>
                </div>
                <br/>
                <br/>

                date: {utils.getDate()}<br/>

                <br/>
                <br/>

                status: {query.status}<br/>
                <br/>
                json: {JSON.stringify(query.data)}<br/>

                <br/>
                <br/>

                <button
                    onClick={() => {
                        mutation.mutate({
                            id: Date.now(),
                            title: 'Do Laundry',
                        });
                    }}
                >
                    Add Todo
                </button>
            </div>
            <p className="read-the-docs">
                Click on the Vite and React logos to learn more
            </p>
        </>
    );
}
