import React from 'react';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {skSK, enUS, csCZ, huHU} from '@mui/x-date-pickers/locales';
import 'dayjs/locale/sk';
import 'dayjs/locale/en';
import 'dayjs/locale/cs';
import 'dayjs/locale/hu';

interface DateInterface {
    onChange: (date) => void;
    value: string;
    placeholder?: string;
    format?: string;
    label?: string;
    required?: boolean;
    disablePast?: boolean;
    disableFuture?: boolean;
    maxDateDays?: number;
}

export default function Date({
    onChange,
    value,
    placeholder,
    format,
    label,
    required,
    disablePast,
    disableFuture,
    maxDateDays}
    :DateInterface) {
    const [selectedDate, setSelectedDate] = React.useState(value ? dayjs(value) : null);

    /**
     *
     * @param {string} newDate
     */
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);

        if (onChange) {
            onChange(newDate);
        }
    };

    const maxDate = dayjs().add(maxDateDays, 'day');

    const languages = {
        sk: skSK,
        en: enUS,
        cz: csCZ,
        hu: huHU,
    };

    const language = localStorage.getItem('mySPS_language') || 'sk';

    return (
        <div className='dateWrapper'>
            {label && <label className='formElementLabel'>{label}{required ? <span className='required'>*</span> : null}</label>}
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={dayjs.locale(language)}
                localeText={languages[language]?.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DatePicker
                    value={selectedDate}
                    onChange={handleDateChange}
                    format={format || 'DD.MM.YYYY'}
                    className='datePicker'
                    reduceAnimations={true}
                    slotProps={{textField: {placeholder: placeholder}}}
                    disableFuture={disableFuture}
                    disablePast={disablePast}
                    maxDate={maxDateDays ? maxDate : null}
                />
            </LocalizationProvider>
        </div>
    );
}
