import {useState} from 'react';
import Button from '@/components/formElements/Button';
import utils from '@/utils/Utils';
import OtpInput from '@/components/formElements/OtpInput';
import toast from 'react-hot-toast';
import CnForm from '@/helpers/tracking/CnForm';
import checkRequiredFields from '@/helpers/tracking/checkRequiredFields';
import Loader from '@/components/Loader';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

export default function QuestionsToAdditionalServicesPopup({data, closePopup}: PopupProps) {
    const [customerIdPrefix, setCustomerIdPrefix] = useState('');
    const [customerIdNumber, setCustomerIdNumber] = useState('');
    const [load, setLoad] = useState(false);
    const [auth, setAuth] = useState(false);
    const shipmentInfo = data.shipmentInfo[0];
    const [fields, setFields] = useState({
        shipmentNr: {
            name: 'shipmentNumber',
            hidden: true,
            value: shipmentInfo.shipmentNr,
        },
        firstName: {
            name: 'firstName',
            label: 'LBL_FIRST_NAME',
            required: true,
        },
        lastName: {
            name: 'lastName',
            label: 'LBL_LAST_NAME',
            required: true,
        },
        phone: {
            name: 'phone',
            label: 'LBL_PHONE',
            required: true,
            value: shipmentInfo.recipientPhone,
        },
        email: {
            name: 'email',
            label: 'LBL_EMAIL',
            required: true,
            type: 'email',
            value: shipmentInfo.recipientMail,
        },
        complaintDescription: {
            name: 'complaintDescription',
            label: 'LBL_ISSUE_DESCRIPTION',
            required: true,
            fieldType: 'textarea',
            className: 'trackingFormSpanTwoColumns',
        },
    });

    /**
    * @param {string} value
    * @param {string} name
    */
    const changeValue = (value, name) => {
        setFields((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            },
        }));
    };

    /**
     *
     */
    const authCustomerId = async () => {
        try {
            const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
            const response = await utils.post('tracking/authCustomerId', {
                shipmentNr: shipmentInfo.shipmentNr,
                customerId: `${customerIdPrefix}-${customerIdNumber}`,
            }, header);

            if (response?.data?.auth) {
                toast.success(utils.useTranslate('LBL_AUTH_CUSTOMER_ID_SUCCESS'));
                setAuth(true);
            } else {
                toast.error(utils.useTranslate('LBL_AUTH_CUSTOMER_ID_FAIL'));
            }
        } catch (error) {
            console.error('error', error);
            toast.error(utils.useTranslate('LBL_ERROR'));
            closePopup();
        }
    };

    /**
     *
     */
    const sendForm = async () => {
        if (!checkRequiredFields(fields, null, setFields) || load) {
            return;
        }

        setLoad(true);
        const postData = {
            type: 'se',
        };

        Object.keys(fields).forEach((key) => {
            const field = fields[key];
            postData[key] = field.value;
        });

        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};

        try {
            const response = await utils.post('complaint/create', postData, header);

            if (response.status) {
                closePopup();
                toast.success(utils.useTranslate('LBL_QUESTION_WAS_ASKED'));
                setLoad(false);
            } else {
                closePopup();
                toast.error(utils.useTranslate('LBL_ERROR_CREATE_QUESTION'));
                setLoad(false);
            }
        } catch (error) {
            console.error('error', error);
            setLoad(false);
        }
    };

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent customerIdAuthPopup">
                {!auth ?
                    <div className='customerIdForm'>
                        <div className='customerIdAuthHeader'>
                            <span className='icon iconfas-lock' />
                            <span>Zadejte zákaznícké číslo</span>
                        </div>
                        <div className='customerIdAuth'>
                            <OtpInput className='authInput' length={3} value={customerIdPrefix} onChange={setCustomerIdPrefix} />
                            <div className='separator' />
                            <OtpInput className='authInput' length={6} value={customerIdNumber} onChange={setCustomerIdNumber} />
                        </div>
                    </div> :
                    <div className='trackingForm'>
                        <CnForm loaded={true} fields={fields} onChange={changeValue}/>
                    </div>
                }
            </div>

            <div className="popupButtons">
                <Button onClick={() => closePopup()} variant='light'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                </Button>
                <Button onClick={() => {
                    auth ? sendForm() : authCustomerId();
                }} variant='primary'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                </Button>
            </div>
            {load ? <Loader /> : null}
        </div>
    );
}
