import {useEffect, useState, useCallback, useMemo} from 'react';
import Button from '@components/formElements/Button';
import utils from 'utils';
import checkRequiredFields from '@/helpers/tracking/checkRequiredFields';
import toast from 'react-hot-toast';
import CnForm from '@/helpers/tracking/CnForm';
import Radio from '@/components/formElements/Radio';
import {MapContainer, TileLayer, Marker} from 'react-leaflet';
import markerIcon from '@img/sps_pointer_map.svg';
import L from 'leaflet';
import MapPopup from '@/components/Parts/Tracking/MapPopup';
import MarkerClusterGroup from 'react-leaflet-cluster';
import Sk from '@/components/formElements/Sk';
import Loader from '@/components/Loader';
import {Autocomplete as AutocompleteBase} from '@mui/joy';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

interface RenderOptionProps {
    ownerState?: any; // Define the correct type for ownerState if you have one
    [key: string]: any; // For other attributes
}

export default function FlexibleDelivery({data, closePopup}: PopupProps) {
    const [flexibleDeliveryData, setFlexibleDeliveryData] = useState<FlexibleDeliveryType>({} as FlexibleDeliveryType);
    const [parcelShops, setParcelShops] = useState([]);
    const [coordinates, setCoordinates] = useState(null);
    const [loadMap, setLoadMap] = useState(false);
    const [load, setLoad] = useState(false);
    const [selectedParcel, setSelectedParcel] = useState(null);
    const [originalName, setOriginalName] = useState('');
    const [fields, setFields] = useState({
        shipmentNr: {name: 'shipmentNr', label: 'LBL_SHIPMENT_NR', required: true, readonly: true, hidden: true},
        deliveryDate: {name: 'deliveryDate', label: 'LBL_DELIVERY_DATE', required: true, fieldType: 'enum', options: 'deliveryDays'},
        deliveryType: {name: 'deliveryType', label: 'LBL_DELIVERY_TYPE', required: true,
            fieldType: 'enum', options: 'allowedDeliveryTypes', hidden: true},
        deliveryName: {name: 'deliveryName', label: 'LBL_DELIVERY_NAME', required: true},
        deliveryPhone: {name: 'deliveryPhone', label: 'LBL_DELIVERY_PHONE', required: true, value: null},
        deliveryMail: {name: 'deliveryMail', label: 'LBL_DELIVERY_MAIL', type: 'email', value: null},
        deliveryStreet: {name: 'deliveryStreet', label: 'LBL_DELIVERY_STREET', required: true, readonly: false},
        deliveryHouseNumber: {name: 'deliveryHouseNumber', label: 'LBL_DELIVERY_HOUSE_NUMBER', readonly: false},
        deliveryCity: {name: 'deliveryCity', label: 'LBL_DELIVERY_CITY', required: true, readonly: false},
        deliveryZipCode: {name: 'deliveryZipCode', label: 'LBL_DELIVERY_ZIP_CODE', required: true, readonly: false},
    });
    const shipmentInfo = data.shipmentInfo[0];

    /**
    * @param {string} value
    * @param {string} name
    */
    const changeValue = useCallback((value, name) => {
        setFlexibleDeliveryData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }, []);

    /**
    * @param {Object} state
    */
    const changeAddressReadonly = useCallback((state) => {
        setFields((prevFields) => ({
            ...prevFields,
            deliveryStreet: {...prevFields.deliveryStreet, readonly: state},
            deliveryHouseNumber: {...prevFields.deliveryHouseNumber, readonly: state},
            deliveryCity: {...prevFields.deliveryCity, readonly: state},
            deliveryZipCode: {...prevFields.deliveryZipCode, readonly: state},
        }));
    }, []);

    /**
    * @returns {void}
    */
    const saveFlexibleDelivery = useCallback(async () => {
        if (!checkRequiredFields(fields, flexibleDeliveryData, setFields) || load) {
            return;
        }
        setLoad(true);

        const postData = {};
        Object.keys(fields).forEach((key) => {
            const field = fields[key];
            postData[field.name] = flexibleDeliveryData[field.name];
        });

        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};

        try {
            const response = await utils.post('tracking/saveFlexibleDelivery', postData, header);

            if (response?.status) {
                toast.success(utils.useTranslate('LBL_SAVE_FLEXIBLE_DELIVERY_SUCCESS'));
                closePopup();
                setLoad(false);
            } else {
                toast.error(utils.useTranslate(response.data?.errorData));
                closePopup();
                setLoad(false);
            }
        } catch (error) {
            console.error(error);
            utils.handleCnTimeOut(error);
            setLoad(false);
        }
    }, [fields, flexibleDeliveryData, shipmentInfo.shipmentNr, closePopup, load]);

    useEffect(() => {
        if (flexibleDeliveryData.deliveryType === 'ADDRESS') {
            changeValue(originalName, 'deliveryName');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flexibleDeliveryData.deliveryType]);

    useEffect(() => {
        /**
         *
         */
        const getFlexibleDelivery = async () => {
            const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
            try {
                const response = await utils.post('tracking/getFlexibleDelivery', {shipmentNr: shipmentInfo.shipmentNr}, header);
                if (response.status) {
                    setFlexibleDeliveryData(response.data);
                    setFields((prevFields) => ({
                        ...prevFields,
                        deliveryDate: {...prevFields.deliveryDate, options: response.data.deliveryDays},
                        deliveryStreet: {...prevFields.deliveryStreet, readonly: response.data.deliveryType !== 'ADDRESS'},
                        deliveryHouseNumber: {...prevFields.deliveryHouseNumber, readonly: response.data.deliveryType !== 'ADDRESS'},
                        deliveryCity: {...prevFields.deliveryCity, readonly: response.data.deliveryType !== 'ADDRESS'},
                        deliveryZipCode: {...prevFields.deliveryZipCode, readonly: response.data.deliveryType !== 'ADDRESS'},
                    }));
                    setOriginalName(response.data.deliveryName);
                } else {
                    toast.error(utils.useTranslate(response.data.errorData));
                    closePopup();
                }
            } catch (error) {
                console.error(error);
                utils.handleCnTimeOut(error);
            }
        };

        getFlexibleDelivery();
    }, [shipmentInfo.shipmentNr, closePopup]);

    useEffect(() => {
        /**
         *
         */
        const getSPSPlaces = async () => {
            setLoadMap(true);
            if (flexibleDeliveryData.deliveryType === 'PARCEL_SHOP' || flexibleDeliveryData.deliveryType === 'PARCEL_TERMINAL') {
                const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};
                const params = {
                    deliveryType: flexibleDeliveryData.deliveryType,
                    shipmentNr: shipmentInfo.shipmentNr,
                    address: `${flexibleDeliveryData.deliveryStreet} ${flexibleDeliveryData.deliveryHouseNumber}
                     ${flexibleDeliveryData.deliveryCity} ${flexibleDeliveryData.deliveryZipCode}`,
                    center: shipmentInfo.recipientCenter,
                };
                try {
                    const response = await utils.post('tracking/getSPSPlaces', params, header);
                    if (response.status) {
                        setParcelShops(response.data.parcelShops);
                        setSelectedParcel(null);
                        setCoordinates({lat: response.data.lat, lng: response.data.lng});
                    }
                } catch (error) {
                    console.error('error', error);
                } finally {
                    setLoadMap(false);
                }
            }
        };

        getSPSPlaces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shipmentInfo.shipmentNr,
        shipmentInfo.recipientCenter,
        flexibleDeliveryData.deliveryType,
    ]);

    /**
    * @param {Object} parcel
    */
    const setParcelAddress = useCallback((parcel) => {
        if (!parcel?.id) {
            return;
        }
        setSelectedParcel(parcel);
        setFlexibleDeliveryData((prevData) => ({
            ...prevData,
            deliveryStreet: parcel?.address,
            deliveryCity: parcel?.city,
            deliveryZipCode: parcel?.zip,
            deliveryHouseNumber: '',
            deliveryName: `${parcel?.id}`,
        }));
    }, []);

    /**
    * @returns {JSX}
    */
    const renderMarkers = useCallback(() => {
        const customMarkerIcon = L.icon({
            iconUrl: markerIcon,
            iconSize: [40, 40], // Adjust the size of the icon as needed
        });

        return parcelShops.map((parcelShop) => (
            <Marker icon={customMarkerIcon} key={parcelShop.id} position={{lat: parcelShop.gps_lat, lng: parcelShop.gps_lng}}>
                <MapPopup onSelect={() => setParcelAddress(parcelShop)} parcelShop={parcelShop} />
            </Marker>
        ));
    }, [parcelShops, setParcelAddress]);

    /**
    * @returns {void | JSX}
    */
    const renderDeliveryTypes = useCallback(() => {
        return flexibleDeliveryData.allowedDeliveryTypes?.map((type) => {
            if (type !== 'ADDRESS' &&
                (!utils.validateEmail(flexibleDeliveryData.deliveryMail) && !utils.validatePhone(flexibleDeliveryData.deliveryPhone))) {
                return;
            }
            return (
                <Radio
                    value={type}
                    name={type}
                    key={type}
                    checked={type === flexibleDeliveryData.deliveryType}
                    label={utils.useTranslate(`LBL_${type}`)}
                    onChange={() => {
                        changeValue(type, 'deliveryType');
                        changeAddressReadonly(type !== 'ADDRESS');
                    }}
                />
            );
        });
    }, [
        flexibleDeliveryData.allowedDeliveryTypes,
        flexibleDeliveryData.deliveryType,
        changeValue,
        changeAddressReadonly,
        flexibleDeliveryData.deliveryMail,
        flexibleDeliveryData.deliveryPhone,
    ]);

    /**
    * @returns {JSX}
    */
    const renderMap = useMemo(() => {
        return flexibleDeliveryData.deliveryType !== 'ADDRESS' && flexibleDeliveryData.deliveryType ?
            <div className='mapWrapper'>
                <Sk className='mapSkeleton' loaded={!loadMap}>
                    <MapContainer center={coordinates} zoom={12} style={{height: '100%', width: '100%'}}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MarkerClusterGroup chunkedLoading>
                            {renderMarkers()}
                        </MarkerClusterGroup>
                        <Marker position={coordinates}>
                        </Marker>
                    </MapContainer>
                </Sk>
            </div> :
            null;
    }, [coordinates, flexibleDeliveryData.deliveryType, renderMarkers, loadMap]);

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                <div className='trackingForm'>
                    <CnForm loaded={flexibleDeliveryData.timestamp} fields={fields} data={flexibleDeliveryData} onChange={changeValue} />
                    <Sk className='trackingFormSpanTwoColumns infoText' loaded={!!flexibleDeliveryData.timestamp}>
                        <div className='trackingFormSpanTwoColumns infoText'>
                            {utils.useTranslate('LBL_SHOW_PARCELS_TYPES')}
                        </div>
                    </Sk>
                    {flexibleDeliveryData.timestamp ?
                        <>
                            <div>
                                {utils.useTranslate('LBL_DELIVERY_TYPE')}
                            </div>
                            <div className='deliveryOptions'>
                                {renderDeliveryTypes()}
                            </div>
                        </> : null}
                    {flexibleDeliveryData.deliveryType !== 'ADDRESS' && flexibleDeliveryData.deliveryType ?
                        <AutocompleteBase
                            className='trackingFormSpanTwoColumns autocomplete'
                            options={parcelShops}
                            getOptionLabel={(option) => option.city ? `${option.address}, ${option.city}, ${option.description}` : ''}
                            renderOption={(props: RenderOptionProps, option) => {
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                const {ownerState, ...htmlProps} = props;
                                htmlProps.key = option.id;
                                htmlProps.className = htmlProps.className + ' autocompleteOption';
                                const distance = option.distance > 1 ?
                                    `(${option.distance.toString().replace('.', ',')} km)` : `(${option.distance * 1000} m)`;
                                return (
                                    <li {...htmlProps}>
                                        {`${option.address}, ${option.city}, ${option.description}, ${distance}`}
                                    </li>
                                );
                            }}
                            value={selectedParcel || null}
                            onChange={(_, newValue) => {
                                setParcelAddress(newValue);
                            }}
                        /> : null}
                    {renderMap}
                </div>
            </div>

            <div className="popupButtons">
                <Button onClick={closePopup} variant='light'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                </Button>
                <Button onClick={saveFlexibleDelivery} variant='primary'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                </Button>
            </div>
            {load ? <Loader/> : null}
        </div>
    );
}
