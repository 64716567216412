/**
 * @param {Array} defs
 * @param {Object} values
 * @param {function} setFields
 * @returns {boolean}
 */
export default function checkRequiredFields(defs, values, setFields) {
    let allFieldsAreGood = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const newDefs = {...defs};

    Object.keys(defs).forEach((key) => {
        const field = defs?.[key];
        let value = values?.[field.name];
        if (!values) {
            value = defs[key].value;
        }

        if (!value && field.required) {
            allFieldsAreGood = false;
            newDefs[key] = {
                ...field,
                errorHelperText: 'LBL_REQUIRED_FIELD',
                error: true,
            };
        }

        if (field.type === 'email' && value && !emailRegex.test(value)) {
            allFieldsAreGood = false;
            newDefs[key] = {
                ...field,
                errorHelperText: 'LBL_INVALID_EMAIL_VALUE',
                error: true,
            };
        }
    });

    setFields(newDefs);

    return allFieldsAreGood;
}
