import {Link} from 'react-router-dom';
import {useContext} from 'react';
import AppContext from '@context/AppContext';
import Button from '@components/formElements/Button';
import utils from 'utils';

import spsFullLogo from '@img/SPS_Logo_Full.svg';

interface FooterProps {
    footerItems: FooterItem[];
    fromContext?: boolean;
}

export default function Footer({footerItems, fromContext}: FooterProps) {
    const contextFooterItems = useContext(AppContext).defs.footer;
    const footerItemsData = fromContext ? contextFooterItems : footerItems;

    return (
        <div className='footerWrapper'>
            <div className='footerContentGrid'>
                <img className='footerLogo' src={spsFullLogo} alt='sps logo'/>

                {/* Footer menu nav links */}
                <div className='footerMenu'>
                    {footerItemsData.filter((item: FooterItem) => item.type === 'link').map((item) => (
                        <Link key={item.label} to={item.link} className='footerMenuItem'>
                            {utils.useTranslate(item.label)}
                        </Link>
                    ))}
                    <div className='footerCopyMobile'>
                        {utils.useTranslate('LBL_COPYRIGHT')}
                    </div>
                </div>

                {/* Footer buttons */}
                <div className='footerButtonBox'>
                    {footerItemsData.filter((item: FooterItem) => item.type === 'button').map((item) => (
                        <Button key={item.label} className='footerMenuItem' link={item.link}>
                            {utils.useTranslate(item.label)}
                        </Button>
                    ))}
                </div>

                {/* Footer social media links */}
                <div className='footerBox'>
                    <div className='footerSocialMedia'>
                        {footerItemsData.filter((item: FooterItem) => item.type === 'icon').map((item) => (
                            <a
                                key={item.label}
                                href={item.link}
                                className={'footerSocialMediaItem ' + item.icon}
                                title={utils.useTranslate(item.label)}
                            />
                        ))}
                    </div>
                    <div className='footerCopy'>
                        {utils.useTranslate('LBL_COPYRIGHT')}
                    </div>
                </div>
            </div>
        </div>
    );
}
