import {useState, useCallback} from 'react';
import Button from '@/components/formElements/Button';
import utils from '@/utils/Utils';
import CnForm from '@/helpers/tracking/CnForm';
import checkRequiredFields from '@/helpers/tracking/checkRequiredFields';
import Loader from '@/components/Loader';
import toast from 'react-hot-toast';
import {Badge} from '@mui/material';

interface PopupProps {
    id?: string;
    data: any;
    closePopup: () => void;
}

export default function TicketForCustomerServicePopup({data, closePopup}: PopupProps) {
    const shipmentInfo = data.shipmentInfo[0];
    const [load, setLoad] = useState(false);
    const [attachment, setAttachment] = useState({file: null, photo: null});
    const [fields, setFields] = useState({
        shipmentNumber: {name: 'shipmentNumber', label: 'LBL_SHIPMENT_NR', required: true, readonly: true, hidden: true,
            value: shipmentInfo.shipmentNr},
        firstName: {name: 'firstName', label: 'LBL_FIRST_NAME', required: true, error: false, errorHelperText: ''},
        lastName: {name: 'lastName', label: 'LBL_LAST_NAME', required: true, error: false, errorHelperText: ''},
        phone: {name: 'phone', label: 'LBL_DELIVERY_PHONE', required: true, error: false, errorHelperText: ''},
        email: {name: 'email', label: 'LBL_DELIVERY_MAIL', required: true, type: 'email', error: false, errorHelperText: ''},
        message: {name: 'message', label: 'LBL_CS_TEXT', required: true, fieldType: 'textarea', error: false, errorHelperText: '',
            className: 'trackingFormSpanTwoColumns',
        },
    });

    /**
    * @param {string} value
    * @param {string} name
    */
    const changeValue = (value, name) => {
        setFields((prevState) => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
            },
        }));
    };

    /**
     *
     */
    const sendForm = async () => {
        if (!checkRequiredFields(fields, null, setFields) || load) {
            return;
        }
        setLoad(true);

        const postData = {
            file: attachment.file,
            photo: attachment.photo,
        };

        Object.keys(fields).forEach((key) => {
            const field = fields[key];
            postData[key] = field.value;
        });

        const header = {recipientZip: utils.getRecipientZIP(shipmentInfo.shipmentNr)};

        try {
            const response = await utils.post('request/create', postData, header);

            if (response?.status) {
                toast.success(utils.useTranslate('LBL_CS_REQUEST_WAS_CREATED'));
                closePopup();
                window.open(`${window.location.protocol}//${window.location.host}/request/${response.data.requestHash}`, '_blank');
            }
        } catch (error) {
            console.error(error);
            utils.handleCnTimeOut(error);
            if (error?.response?.status === 415) {
                toast.error(utils.useTranslate('LBL_NOT_VALID_FILE_TYPE_IMAGE'));
            } else {
                toast.error(utils.useTranslate('LBL_CS_REQUEST_WAS_NOT_CREATED'));
            }
        } finally {
            setLoad(false);
        }
    };

    /**
     * handleFileUpload - handle file upload
     * @description
     * 1. validate file size (max 10MB)
     * 2. validate file type (only images or pdf,doc,docx,xls,xlsx,zip are allowed)
     * 3. upload file
     * 4. show error if something went wrong
     * 5. show success message if everything is ok
     * 6. set attachment name
     * @param {object} event - file upload event
     * @returns {Promise}
     */
    const handleFileUpload = useCallback(async (event, type) => {
        if (event.target.files.length <= 0) {
            return;
        }

        const file = event.target.files[0];

        // validate file size (max 10MB)
        if (file && file.size > 10485760) {
            toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_TOO_BIG'));
            setAttachment({...attachment, [type]: null});
            return;
        }

        if (type === 'photo' && !file.type.match(/image\/(png|jpg|jpeg|gif|bmp)/)) {
            toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_INVALID'));
            setAttachment({...attachment, [type]: null});
        }

        // validate file type (only images or pdf,doc,docx,xls,xlsx,zip are allowed)
        if (file &&
            !file.type.match(/image\/(png|jpg|jpeg|gif|bmp)/) &&
            // eslint-disable-next-line max-len
            !file.type.match(/application\/(pdf|msword|vnd.openxmlformats-officedocument.wordprocessingml.document|vnd.ms-excel|vnd.openxmlformats-officedocument.spreadsheetml.sheet)/) &&
            // allow csv files
            !file.type.match(/text\/(csv|plain)/)
        ) {
            toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_INVALID'));
            setAttachment({...attachment, [type]: null});
            return;
        }

        // upload file
        if (file) {
            const fileData = new FormData();
            fileData.append('file', file, file.name);

            const response = await utils.upload('file/upload', fileData).catch((error) => {
                console.error('error', error);
                toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_ERROR'));
            });

            if (response.status === true && response.data?.fileName) {
                toast.success(utils.useTranslate('LBL_REQUEST_CREATE_FILE_SUCCESS'));
                setAttachment({...attachment, [type]: response.data?.fileName});
            } else {
                toast.error(utils.useTranslate('LBL_REQUEST_CREATE_FILE_ERROR'));
                setAttachment({...attachment, [type]: null});
                return;
            }
        }
    }, [attachment]);

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                <div className='trackingForm'>
                    <CnForm loaded={true} fields={fields} onChange={changeValue} />
                    <div className='uploadButtons'>
                        <Badge badgeContent={null} className={'emptyBadge ' + (!attachment ? 'hidden' : '')}>
                            <Button
                                className='RequestUploadFileButton'
                                variant="light"
                                id='uploadFileBtn'
                                component="label"
                                version="material"
                            >
                                <span className='iconfas-upload' /> {utils.useTranslate('LBL_BTN_UPLOAD_FILE')}
                                <input
                                    type='file'
                                    className='RequestUploadFileInput hiddenInput'
                                    onChange={(e) => handleFileUpload(e, 'file')}
                                />
                            </Button>
                        </Badge>
                        <Badge badgeContent={null} className={'emptyBadge ' + (!attachment ? 'hidden' : '')}>
                            <Button
                                className='RequestUploadFileButton'
                                variant="light"
                                id='uploadFileBtn'
                                component="label"
                                version="material"
                            >
                                <span className='iconfas-upload' /> {utils.useTranslate('LBL_BTN_UPLOAD_PHOTO')}
                                <input
                                    type='file'
                                    accept=".png, .jpeg, .jpg, .gif, .bmp"
                                    className='RequestUploadFileInput hiddenInput'
                                    onChange={(e) => handleFileUpload(e, 'photo')}
                                />
                            </Button>
                        </Badge>
                    </div>
                </div>
            </div>

            <div className="popupButtons">
                <Button onClick={() => closePopup()} variant='light'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CANCEL_BTN')}</span>
                </Button>
                <Button onClick={() => sendForm()} variant='primary'>
                    <span className='tabLabel bold'>{utils.useTranslate('LBL_CONFIRM_BTN')}</span>
                </Button>
            </div>
            {load ? <Loader /> : null}
        </div>
    );
}
