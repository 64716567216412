import {useMemo} from 'react';
import {Input as InputBase} from '@mui/base/Input';
import CircularProgress from '@mui/material/CircularProgress';

interface InputProps {
    className?: string;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    defaultValue?: string;
    error?: boolean;
    required?: boolean;
    autoComplete?: string;
    type?: string;
    onChange?: (value: string) => void;
    id?: string;
    loading?: boolean;
    statusIcon?: string;
    onIconClick?: () => void;
    warning?: boolean;
    valid?: boolean;
    label?: string;
    errorHelperText?: string;
}

export default function Input({
    className,
    placeholder,
    value,
    disabled,
    defaultValue,
    error,
    required,
    autoComplete,
    type,
    onChange,
    id,
    loading,
    statusIcon,
    onIconClick,
    warning,
    valid,
    label,
    errorHelperText,
}: InputProps) {
    const renderStatus = useMemo(() => {
        /**
         *
         */
        const handleIconClick = () => {
            if (onIconClick) {
                onIconClick();
            }
            return;
        };

        if (loading) {
            return <div onClick={() => handleIconClick()} className='inputStatusWrapper'>
                <CircularProgress className="inputLoading" size="1.3rem"/>
            </div>;
        }

        if (statusIcon === 'success') {
            return <div onClick={() => handleIconClick()} className='inputStatusWrapper'>
                <span className="inputStatus iconfas-success"></span>
            </div>;
        }

        if (statusIcon === 'error') {
            return <div onClick={() => handleIconClick()} className='inputStatusWrapper'>
                <span className="inputStatus iconfas-error"></span>
            </div>;
        }

        if (statusIcon === 'search') {
            return <div onClick={() => handleIconClick()} className='inputStatusWrapper'>
                <span className="inputStatus iconfa-search"></span>
            </div>;
        }

        if (statusIcon === 'warning') {
            return <div className='inputStatusWrapper'><span className="inputStatus iconfas-warning"></span></div>;
        }

        if (!loading && !statusIcon) {
            return null;
        }

        return null;
    }, [loading, statusIcon, onIconClick]);

    const classes = useMemo(() => {
        let cls = className;

        if (warning) {
            cls += ' warning';
        } else if (valid) {
            cls += ' valid';
        }
        return cls;
    }, [className, warning, valid]);

    const requiredPlaceholder = !label && required ? ' *' : '';

    return (<div className='InputWrapper'>
        {label && <label className='formElementLabel'>{label}{required ? <span className='required'>*</span> : null}</label>}
        <InputBase
            className={'Input ' + classes}
            placeholder={placeholder + requiredPlaceholder}
            value={value}
            defaultValue={defaultValue}
            onChange={(event) => onChange && onChange(event.target.value)}
            disabled={disabled}
            error={error}
            required={required}
            autoComplete={autoComplete}
            type={type}
            id={id}
        />
        {renderStatus}
        {error && errorHelperText && <div className='errorText'>{errorHelperText}</div>}
    </div>);
}
