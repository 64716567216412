/**
 * Posts data to the mySPS rest API
 * @param {string} path
 * @param {any} data
 * @param {object} additionalHeaders
 * @returns Promise
 */
export default async function post(path: string, data: any, additionalHeaders: object) {
    const apiKey = '6VS314WZ+YtFPrYqqV2EZAoxKJNIyr13JGKTWjlOy6jz5zWKr-C8ZnpCJ7ChvZfW';
    const apiUrl = this.config?.api;

    const headers = {
        'Api-Key': apiKey,
        'Content-Type': 'application/json',
        ...additionalHeaders,
    };

    const response = await this.rest.post(apiUrl + path, data, headers);

    return response;
}
