/**
 * getStorage - Get storage value by name
 * @param {string} name
 * @return {any}
 */
export default function getStorage(name: string): any {
    let value = null;
    if (window.localStorage) {
        value = localStorage.getItem(name);
    } else {
        value = localStorage.getItem(name);
    }
    try {
        value = JSON.parse(value);
        return value;
    } catch (e) {
        return value;
    }
}
