/**
 * Upload data to the mySPS rest API via form post
 * @param {string} path
 * @param {any} data
 * @returns Promise
 */
export default async function upload(path: string, data: any) {
    const apiKey = '6VS314WZ+YtFPrYqqV2EZAoxKJNIyr13JGKTWjlOy6jz5zWKr-C8ZnpCJ7ChvZfW';
    const apiUrl = this.config?.api;

    const headers = {
        'Api-Key': apiKey,
        'Content-Type': 'multipart/form-data',
    };

    const response = await this.rest.post(apiUrl + path, data, headers);

    // if status is not true, throw error
    // call .error() to show error message
    if (!response.status) {
        if (this.error) {
            this.error(response.message);
        } else {
            console.error(response.message);
        }
    }

    return response;
}
