/**
 * useTranslateEnum hook
 * @param {string} enumName
 * @param {string} label
 * @returns {any} lang
 */
export default function useTranslateEnum(enumName: string, label: string = null) {
    // add method to lang isLangLoaded
    this.lang.isLangLoaded = () => {
        return !!this.lang?.data && Object.keys(this.lang?.data).length > 0;
    };

    // when used without label, return null
    if (!label) {
        return null;
    }

    if (!this.lang?.data || this.lang?.isLoading || this.lang?.isError) {
        // console.warn('useTranslate >> lang is not loaded yet');
        return label;
    }

    return this.lang?.data?.[enumName]?.[label] || label;
}
