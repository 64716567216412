/**
 * useTranslate hook
 * @param {string} label
 * @returns {any} lang
 */
export default function useTranslate(label: string = null) {
    // add method to lang isLangLoaded
    this.lang.isLangLoaded = () => {
        return !!this.lang?.data && Object.keys(this.lang?.data).length > 0;
    };

    // when used without label, return lang query object
    if (!label) {
        return this.lang;
    }

    if (!this.lang?.data || this.lang?.isLoading || this.lang?.isError) {
        // console.warn('useTranslate >> lang is not loaded yet');
        return label;
    }

    return this.lang?.data?.[label] || label;
}
