/**
 * @param {string} inputDate
 * @param {boolean} onlyDate
 * @returns {string}
 */
export default function formatDate(inputDate, onlyDate = false) {
    const dateObj = new Date(inputDate);

    if (isNaN(dateObj.getTime())) {
        return inputDate;
    } else {
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();

        if (!onlyDate) {
            return `${day}.${month}.${year} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
        }

        return `${day}.${month}.${year}`;
    }
}
