/**
 * removeStorage - remove value from localStorage
 * @param {string} name
 * @return {any}
 */
export default function removeStorage(name: string): any {
    let value = null;
    if (window.localStorage) {
        value = localStorage.removeItem(name);
    } else {
        value = localStorage.removeItem(name);
    }
    try {
        value = JSON.parse(value);
        return value;
    } catch (e) {
        return value;
    }
}
