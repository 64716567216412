import {useMemo} from 'react';
import Button from '@components/formElements/Button';
import utils from 'utils';

interface PopupProps {
    id?: string;
    message: string;
    onConfirm: () => void;
    onClose?: () => void;
    closePopup: () => void;
}

export default function ConfirmPopup({message, closePopup, onConfirm, onClose}: PopupProps) {
    const buttonsDef = useMemo(() => {
        return [
            {
                label: 'LBL_CANCEL_BTN',
                type: 'secondary',
                action: () => {
                    onClose && onClose();
                    closePopup();
                },
                id: 'cancelBtn',
            }, {
                label: 'LBL_CONFIRM_BTN',
                type: 'primary',
                action: () => {
                    onConfirm && onConfirm();
                    closePopup();
                },
                id: 'confirmBtn',
            },
        ];
    }, [onConfirm, onClose, closePopup]);

    return (
        <div className="popupContentWrapper infoPopup">
            <div className="popupContent">
                <p className='popupMessage'>{message}</p>
            </div>

            <div className="popupButtons">
                {buttonsDef && buttonsDef.map((button, index) => (
                    <Button
                        key={index}
                        className={`popupButton ${button.type}`}
                        onClick={button.action}
                        id={button.id}
                    >
                        {utils.useTranslate(button.label)}
                    </Button>
                ))}
            </div>
        </div>
    );
}
