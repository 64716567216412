import {useContext} from 'react';
import AppContext from '@context/AppContext';
import Popup from './Popup';

export default function PopupsWrapper() {
    const {popups} = useContext(AppContext);

    return (
        <>
            {popups.map((popup, index) => (
                <Popup
                    key={index}
                    className={'popupWrapper ' + popup.type + ' ' + popup.className}
                    id={popup.id}
                    title={popup.title}
                    type={popup.type}
                    message={popup.message}
                    buttons={popup.buttons}
                    component={popup.component}
                    onClose={popup.onClose}
                    onConfirm={popup.onConfirm}
                    afterOpen={popup.afterOpen}
                    data={popup.data}
                    cnAction={popup.cnAction}
                    icon={popup.icon}
                />
            ))}
        </>
    );
}
